import { Triangle } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getFlowChartMerge(data: Element) {
  const option = await getBaseCommonProps(data)

  return new Triangle({
    ...option,
    flipY: !data.flipY,
  })
}