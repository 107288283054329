import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Form, Input, Space, Button, Popconfirm, Select, Tag, Message, Grid } from '@arco-design/web-react';
import { IconSearch, IconPlus } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';

import { getFormatList, getFormatStyleOption, putFormatStatus, delFormat, getFormatStructs } from '@/api/slides';

import { formatDate, setSessionStorage } from '@/utils';

export default () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const { cdnUrl } = useContext(GlobalContext);

	const columns: any = [
		{
			title: '缩略图',
			key: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => <img style={{ width: '80px' }} src={cdnUrl + record.preview} />
		},
		{
			title: '名称',
			dataIndex: 'name'
		},
		{
			title: '版式风格',
			dataIndex: 'styleName'
		},
		{
			title: '结构类型',
			key: 'structs',
			render: (_: any, record: any) => <>{record.structs.join(',')}</>
		},
		{
			title: '标签',
			key: 'tags',
			render: (_: any, record: any) => <>{record.tags.join(',')}</>
		},
		{
			title: '评分',
			dataIndex: 'score'
		},
		{
			title: '非会员定价(页)',
			dataIndex: 'price'
		},
		{
			title: '状态',
			key: 'status',
			render: (_: any, record: any) => (
				<>{record.status == 1 ? <Tag color="#00b42a">已启用</Tag> : <Tag color="#86909c">已下架</Tag>}</>
			)
		},
		{
			title: '提交人',
			dataIndex: 'creatorName'
		},
		{
			title: '创建时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			width: 220,
			fixed: 'right',
			key: 'action',
			render: (_: any, record: any) => (
				<Space>
					<Button
						type="text"
						onClick={() => {
							setSessionStorage('slideEdit', JSON.stringify(record));
							navigate(`/editor/slides/${record.id}`);
						}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此版式？" onOk={() => handleDel(record)}>
						<Button type="text">删除</Button>
					</Popconfirm>
					<Popconfirm
						position="left"
						title={`是否${record.status == 1 ? '下架' : '上架'}此版式？`}
						onOk={() => handleStatus(record)}>
						<Button type="text">{record.status == 1 ? '下架' : '上架'}</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	const [styleList, setStyleList] = useState<any[]>([]);
	const [layoutOption, setLayoutOptions] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};
	const handleSearch = () => {
		if (page == 1) {
			getList();
		} else {
			setPage(1);
		}
	};

	// const handleEdit = () => {};

	const getList = async () => {
		setLoading(true);
		const values = form.getFieldsValue();
		let res = await getFormatList({
			...values,
			page,
			size: 10
		});
		setLoading(false);
		res.data.list.forEach((it: any) => {
			if (it.preview.indexOf('?') > -1) {
				it.preview = it.preview + '&imageView2/1/w/160/h/90';
			} else {
				it.preview = it.preview + '?imageView2/1/w/160/h/90';
			}
		});

		setList(res.data.list);
		setTotal(res.data.total);
	};

	const getStyleList = async () => {
		let res: any = await getFormatStyleOption();
		if (res.code == 200) {
			setStyleList(res.data);
		}
	};
	const getLayoutList = async () => {
		let res = await getFormatStructs('1');
		setLayoutOptions(res.data);
	};

	// 上下架
	const handleStatus = async (item: any) => {
		let status = item.status == 1 ? 2 : 1;
		let res = await putFormatStatus(status, item.id);
		if (res.code == 200) {
			getList();
		}
	};
	// 删除
	const handleDel = async (item: any) => {
		let res = await delFormat(item.id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	useEffect(() => {
		getStyleList();
		getLayoutList();
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={6}>
							<FormItem field="style" label="风格">
								<Select allowClear placeholder="请选择风格类型">
									{styleList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="layout" label="结构">
								<Select allowClear placeholder="请选择结构类型">
									{layoutOption.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						{/* <Col span={6}>
							<FormItem field="score" label="评分">
								<Select allowClear placeholder="请选择评分">
									{scoreOption.map((item) => (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col> */}
						<Col span={6}>
							<FormItem field="keywords" label="关键字">
								<Input placeholder="请输入关键字" />
							</FormItem>
						</Col>
						{/* <Col span={6}>
							<FormItem field="integral" label="积分">
								<Input placeholder="请输入积分" />
							</FormItem>
						</Col> */}
						<Col span={6}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									<Button
										type="primary"
										icon={<IconPlus />}
										onClick={() => {
											navigate('/editor/slides');
										}}>
										新增版式
									</Button>
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
