import { Rect, RectProps } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getRect(data: Element) {
  const option: Partial<RectProps> = await getBaseCommonProps(data)
  option.width = data.width
  option.height = data.height
  
  const rect = new Rect(option)

  return rect
}