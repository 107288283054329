import { customAlphabet } from 'nanoid';

import { SourceData, ElementData, Xfrm, Fill, ColorData, outerShdw, Style } from '@/types/source';
import {
	TextData,
	ShapeData,
	ImageData,
	TextLineData,
	Element,
	Gradient,
	Align,
	BaseElement,
	TextSingleData,
	Shadow,
	Vertical,
	GroupData,
	LineData,
	SlideData,
	Theme,
	DefaultStyle
} from '@/types/element';
import {
	getBaseXfrmInfo,
	getFill,
	getCustomShape,
	checkValidFill,
	getBaseShapeType,
	getLineStyle,
	getLineEndType,
	getGroupXfrmInfo
} from './help';
import { emuToPixel, ptToPixel, emuToPercent, emuToAngle, emuToPound } from './unit';

export const nanoid = customAlphabet('1234567890', 6);

interface ElementOption {
	group?: {
		id: string;
		xfrm?: Xfrm;
		fill?: Fill;
	}[];
	groupIds?: BaseElement['groupIds'];
	type?: Element['type'];
}

const TextAlignMap: { [key: string]: Align } = {
	ctr: 'center', // 居中
	r: 'right', // 居右
	l: 'left',
	just: 'justify', // 两端对齐
	dist: 'justify-center' // 分散对齐
};

const TextVerticalMap: { [key: string]: Vertical } = {
	ctr: 'center',
	t: 'top',
	b: 'bottom'
};

export default class PptParse {
	useOriginData: boolean = true;

	themeMap: Theme = {};

	layoutMasterMap: { [k: string]: string } = {};

	masterList: SlideData[] = [];

	currentTheme: { [k: string]: string } = {};

	currentMaster: SlideData | undefined = undefined;

	slideWidth: number = 0;

	slideHeight: number = 0;

	picMap: { [key: string]: string } = {};

	defaultStyles: { [key: string]: DefaultStyle } = {};

	constructor({ useOriginData = true } = {}) {
		this.useOriginData = useOriginData;
	}

	getData(data: SourceData) {
		const { res } = data;
		if (res?.theme) {
			Object.keys(res.theme).forEach((key: string) => {
				const themeLayout = res.theme[key];
				if (themeLayout?.themeElements?.clrScheme) {
					this.themeMap = {
						...this.themeMap,
						[key]: {
							name: themeLayout.name,
							color: Object.entries(themeLayout.themeElements.clrScheme).reduce(
								(total: any, [key, val]) => ({
									...total,
									[key]: this.getFill(val)
								}),
								{}
							)
						}
					};
				}
			});
		}

		const width = emuToPixel(data.pres.sldSz.w);
		const height = emuToPixel(data.pres.sldSz.h);

		this.slideWidth = width;
		this.slideHeight = height;

		this.picMap = res.pic || {};

		this.defaultStyles = this.getDefaultTextStyle(data.pres.defaultTs);
		this.masterList = this.getMaster(data.pres.sldMasters);
		const layoutList = this.getLayout(data.res.layout);
		const slideList = this.getSlides(data.pres.slides);

		return {
			defaultStyles: this.defaultStyles,
			themeMap: this.themeMap,
			masterList: this.masterList,
			layoutList,
			slideList,
			fontList: data.res.font?.f0 || [],
			picMap: this.picMap,
			viewportSize: {
				width,
				height,
				type: data.pres.sldSz.type || ''
			}
		};
	}

	getDefaultTextStyle(data: SourceData['pres']['defaultTs']) {
		const styles: { [k: string]: DefaultStyle } = {};
		Object.entries(data.styles).forEach(([key, val]: [string, Style?]) => {
			if (val) {
				styles[key] = {};
				if (val.defRPr?.sz) {
					styles[key].fontSize = ptToPixel(val.defRPr.sz);
				}
				if (val.algn) {
					styles[key].align = TextAlignMap[val.algn];
				}
				if (val.defTabSz) {
					styles[key].tabSize = emuToPixel(val.defTabSz);
				}
				if (val.marL) {
					styles[key].marginLeft = emuToPixel(val.marL);
				}
				if (val.marR) {
					styles[key].marginRight = emuToPixel(val.marR);
				}
				if (val.lnSpc?.spcPct) {
					styles[key].lineHeight = emuToPercent(val.lnSpc.spcPct);
				}
				// if (val.defRPr?.fill?.color) {
				//   styles[key].fill = this.getFill(val.defRPr.fill.color)
				// }
			}
		});

		return styles;
	}

	getMaster(data: SourceData['pres']['sldMasters']) {
		return data.map((slide) => {
			const data: SlideData = {
				id: nanoid(),
				themeName: slide.themeRef || '',
				layouts: slide.sldLayoutRefs || [],
				elements: []
				// elements: slide.cSld ? this.getElement(slide.cSld.spTree.ps) : []
			};
			const themeMap = data.themeName && this.themeMap[data.themeName]?.color;
			if (slide.clrMap && themeMap) {
				data.colorMap = Object.entries(slide.clrMap).reduce(
					(total, [key, val]) => ({
						...total,
						[key]: themeMap[val] || ''
					}),
					{}
				);
			}
			if (slide.sldLayoutRefs?.length) {
				this.layoutMasterMap = {
					...this.layoutMasterMap,
					...slide.sldLayoutRefs.reduce(
						(total, cur) => ({
							...total,
							[cur]: data.id
						}),
						{}
					)
				};
			}
			if (data.colorMap) this.currentTheme = data.colorMap;
			if (slide.cSld?.bg?.bgpr?.fill && checkValidFill(slide.cSld.bg.bgpr.fill)) {
				data.background = this.getColor(slide.cSld.bg.bgpr.fill);
			}
			if (slide.cSld?.bg?.bgRef?.color) {
				data.background = this.getFill(slide.cSld.bg.bgRef.color);
			}
			if (slide.cSld) {
				data.elements = this.getElement(slide.cSld.spTree.ps);
			}
			return data;
		});
	}

	setLayoutTheme(ref: string) {
		const masterInfo = this.masterList.find((item) => item.id == this.layoutMasterMap[ref]);
		if (masterInfo) this.currentMaster = masterInfo;
		if (masterInfo?.colorMap) {
			this.currentTheme = {
				...(masterInfo.themeName ? this.themeMap[masterInfo.themeName].color : {}),
				...masterInfo.colorMap
			};
		}
	}

	getLayout(data: SourceData['res']['layout']) {
		return Object.entries(data).map(([name, slide]) => {
			this.setLayoutTheme(name);

			const data: SlideData = {
				id: nanoid(),
				name,
				layoutName: slide.cSld?.name,
				elements: slide.cSld?.spTree.ps ? this.getElement(slide.cSld?.spTree.ps) : []
			};
			if (slide.cSld?.bg?.bgpr?.fill && checkValidFill(slide.cSld.bg.bgpr.fill)) {
				data.background = this.getColor(slide.cSld.bg.bgpr.fill);
			}
			return data;
		});
	}

	getSlides(data: SourceData['pres']['slides']) {
		return data.map((slide) => {
			this.setLayoutTheme(slide.layoutRef);

			const data: SlideData = {
				id: nanoid(),
				layoutRef: slide.layoutRef,
				elements: this.getElement(slide.cSld.spTree.ps),
				show: slide.show == true
			};
			if (slide.cSld.bg?.bgpr?.fill && checkValidFill(slide.cSld.bg.bgpr.fill)) {
				data.background = this.getColor(slide.cSld.bg.bgpr.fill);
			}
			return data;
		});
	}

	getElement(data: ElementData[] = [], opt?: ElementOption) {
		const elementList: Element[] = [];
		for (const element of data) {
			if (element.grpSpPr) {
				elementList.push(this.getGroup(element, opt));
				continue;
			}
			if (element.blipFill) {
				elementList.push(this.getImage(element, opt));
				continue;
			}
			if (element.type == 4) {
				elementList.push(this.getLine(element, opt));
				continue;
			}
			if (element.type == 1 && element.txBody) {
				elementList.push(this.getText(element, opt));
				continue;
			}
			console.log(element);
		}
		return elementList;
	}

	getBaseElement(data: ElementData, opt: ElementOption = {}) {
		const { groupIds } = opt;
		const baseData: BaseElement = {
			id: nanoid(),
			useBgFill: data.useBgFill || false,
			name: data.nvSpPr?.cNvPr?.name || data.nvPicPr?.cNvPr.name || '',
			hidden: data.nvSpPr?.cNvPr?.hidden || data.nvPicPr?.cNvPr.hidden || false,
			...getBaseXfrmInfo(data?.spPr?.xfrm)
		};
		if (this.useOriginData) baseData.d = data;

		if (groupIds?.length) baseData.groupIds = groupIds;

		if (data.spPr?.effect?.softEdge?.rad) {
			baseData.rad = emuToPound(data.spPr.effect.softEdge.rad);
		}

		// 背景色
		if (data.spPr?.fill && checkValidFill(data.spPr.fill)) {
			baseData.background = this.getColor(data.spPr.fill);
		}
		if (data.spPr?.fill?.type == 3 && data.spPr.fill.blip?.embed) {
			baseData.backgroundImage = this.picMap[data.spPr.fill.blip.embed];
			if (data.spPr.fill.stretch?.fillRect) {
				const { l = 0, t = 0, r = 0, b = 0 } = data.spPr.fill.stretch.fillRect;
				baseData.backgroundImageRect = {
					left: emuToPercent(l),
					top: emuToPercent(t),
					right: emuToPercent(r),
					bottom: emuToPercent(b)
				};
			}
		}

		if (!data.spPr?.fill && !baseData.background && data.style?.fillRef?.color && data.style.fillRef.idx) {
			if (data.useBgFill) {
				baseData.background = this.currentMaster?.background || '#000';
			} else {
				baseData.background = this.getFill(data.style.fillRef.color);
			}
		}

		// 边框
		if (data.spPr?.ln?.w || (data.spPr?.ln?.fill && checkValidFill(data.spPr.ln.fill))) {
			baseData.border = {
				width: data.spPr.ln?.w ? emuToPixel(data.spPr.ln.w) : 1,
				style: 'solid'
			};
			if (data.spPr?.ln?.fill && checkValidFill(data.spPr.ln.fill)) {
				baseData.border.color = this.getColor(data.spPr.ln.fill);
			}
			if (data.spPr.ln.miter?.lim && baseData.border) {
				baseData.border.miterLimit = emuToPercent(data.spPr.ln.miter.lim);
			}
			if (!baseData.border.color && !data.spPr.ln.fill && data.style?.lnRef?.color) {
				baseData.border.color = this.getFill(data.style.lnRef.color);
			}
			if (data.spPr.ln.prstDash?.val) {
				baseData.border.style = getLineStyle(data.spPr.ln.prstDash.val);
			}
		}

		if (data.spPr?.effect?.outerShdw) {
			baseData.shadow = this.getShadow(data.spPr.effect.outerShdw, {
				width: baseData.width,
				height: baseData.height
			});
		}

		return baseData;
	}

	getGroup(data: ElementData, opt: ElementOption = {}) {
		const groupId = nanoid();
		const group = { id: groupId, xfrm: data?.grpSpPr?.xfrm, fill: data.grpSpPr?.fill };

		const groupData: GroupData = {
			...getGroupXfrmInfo(data.grpSpPr?.xfrm),
			id: groupId,
			name: data.nvGrpSpPr?.cNvPr.name || '',
			hidden: data.nvGrpSpPr?.cNvPr.hidden || false,
			type: 'group',
			elements: this.getElement(data.ps || [], {
				group: opt.group ? [...opt.group, group] : [group],
				groupIds: opt.groupIds ? [...opt.groupIds, groupId] : [groupId]
			})
		};
		if (data.grpSpPr?.fill && checkValidFill(data.grpSpPr.fill)) {
			groupData.background = this.getColor(data.grpSpPr.fill);
		}
		if (this.useOriginData) groupData.d = data;

		return groupData;
	}

	getText(data: ElementData, opt: ElementOption = {}) {
		const isTextBox: boolean = data?.nvSpPr?.cNvSpPr?.txBox || false;

		const text: TextData = {
			...this.getBaseElement(data, opt),
			text: [],
			type: 'text',
			isTextBox,
			vertical: TextVerticalMap[data.txBody?.bodyPr.anchor || 't'] || 'top',
			margin: {},
			autoFit: data.txBody?.bodyPr.spAutoFit || false,
			wrap: data.txBody?.bodyPr.wrap || 'square',
			noAutoWrap: data.txBody?.bodyPr.noAutofit || false,
			layout: data.txBody?.bodyPr.vert == 'eaVert' ? 'vertical' : 'horizontal'
		};

		const shape = this.getShapeInfo(data);
		if (shape) text.shape = shape;

		if (data.txBody?.bodyPr) {
			// if (data.txBody.bodyPr.wrap) {
			//   text.wrap = data.txBody.bodyPr.wrap
			// }
			if (typeof data.txBody.bodyPr.lIns == 'number') {
				text.margin.left = emuToPixel(data.txBody.bodyPr.lIns);
			}
			if (typeof data.txBody.bodyPr.tIns == 'number') {
				text.margin.top = emuToPixel(data.txBody.bodyPr.tIns);
			}
			if (typeof data.txBody.bodyPr.rIns == 'number') {
				text.margin.right = emuToPixel(data.txBody.bodyPr.rIns);
			}
			if (typeof data.txBody.bodyPr.bIns == 'number') {
				text.margin.bottom = emuToPixel(data.txBody.bodyPr.bIns);
			}
		}

		if (data.nvSpPr?.nvPr?.ph?.type) {
			text.paragraphType = data.nvSpPr.nvPr.ph.type;
		}

		// 形状默认黑色边框
		if (!isTextBox && !text.border) {
			text.border = { width: 1, style: 'solid' };
		}
		if (text.border && !text.border.color && !data.spPr?.ln?.fill && data.style?.lnRef?.color) {
			text.border.color = this.getFill(data.style.lnRef.color);
		}

		if (text.border && data?.spPr?.ln?.prstDash?.val) {
			text.border.style = getLineStyle(data.spPr.ln.prstDash.val);
		}

		const { ps = [] } = data?.txBody || { ps: [] };
		for (const line of ps) {
			const textLine: TextLineData = {
				text: [],
				align: TextAlignMap[line.ppr?.algn || data.txBody?.lstStyle?.styles?.lvl1pPr?.algn || 'l'] || 'left',
				lineHeight: emuToPercent(
					line.ppr?.lnSpc?.spcPct || data.txBody?.lstStyle?.styles?.lvl1pPr?.lnSpc?.spcPct || 100000
				),
				indentLevel: 0
			};
			if (line.ppr?.lvl) textLine.indentLevel = line.ppr.lvl;
			const spaceBefore = line.ppr?.spcBef?.spcPct || data.txBody?.lstStyle?.styles?.lvl1pPr?.spcBef?.spcPct;
			if (spaceBefore) {
				textLine.spaceBefore = emuToPercent(spaceBefore);
			}
			for (const single of line.rs || []) {
				if (single.t) {
					const fontSize = ptToPixel(
						single?.rpr?.sz
							? single.rpr.sz
							: data.txBody?.lstStyle?.styles?.lvl1pPr?.defRPr?.sz || (text.autoFit ? 0 : 1800)
					);
					const d: TextSingleData = {
						text: single.t || '',
						fontSize,
						fontWeight: single.rpr?.b ? 'bold' : 'normal',
						fontStyle: single.rpr?.i ? 'italic' : 'normal',
						underline: single.rpr?.u == 'sng',
						lineThrough: single.rpr?.strike == 'sngStrike',
						// fontFamily: single.rpr?.latin?.typeface || single.rpr?.cs?.typeface || single.rpr?.ea?.typeface,
						color: data?.style?.fontRef?.color ? this.getFill(data.style.fontRef.color) : '',
						lang: (single.rpr?.lang || 'en-US') as TextSingleData['lang'],
						altLang: single.rpr?.altLang || ('en-US' as TextSingleData['altLang'])
					};
					if (single.rpr?.ea) {
						d.eaTypeface = single.rpr.ea.typeface;
					}
					if (single.rpr?.latin) {
						d.latinTypeface = single.rpr.latin.typeface;
					}
					if (single.rpr?.sym) {
						d.symTypeFace = single.rpr.sym.typeface;
					}
					if (single.rpr?.fill && checkValidFill(single.rpr.fill)) {
						d.color = this.getColor(single.rpr.fill);
					}
					if (
						!d.color &&
						data.txBody?.lstStyle?.styles?.lvl1pPr?.defRPr?.fill &&
						checkValidFill(data.txBody?.lstStyle?.styles?.lvl1pPr?.defRPr?.fill)
					) {
						d.color = this.getColor(data.txBody.lstStyle.styles.lvl1pPr.defRPr.fill);
					}
					if (single.rpr?.ln?.fill && checkValidFill(single.rpr?.ln.fill)) {
						d.border = {
							color: this.getColor(single.rpr?.ln.fill),
							width: emuToPound(single.rpr.ln?.w || 12700),
							style: 'solid'
						};
					} else {
						if (!d.color) d.color = '#000';
					}
					textLine.text.push(d);
				}
			}
			if (textLine.text.length) text.text.push(textLine);
		}

		return text;
	}

	getImage(data: ElementData, opt: ElementOption = {}) {
		const image: ImageData = {
			...this.getBaseElement(data, opt),
			type: 'image',
			opacity: 1,
			src: '',
			dpi: data.blipFill?.dpi || 0,
			rotWithShape: data.blipFill?.rotWithShape || false
		};
		const shape = this.getShapeInfo(data);
		if (shape) image.shape = shape;
		if (data.blipFill?.blip?.embed) {
			image.src = this.picMap[data.blipFill.blip.embed];
		}
		if (data.blipFill?.blip?.alphaModFix) {
			data.blipFill.blip.alphaModFix.forEach((item) => {
				image.opacity = emuToPercent(item.amt);
			});
		}
		if (data.blipFill?.srcRect) {
			const { t, r, b, l } = data.blipFill.srcRect;
			image.rect = {
				top: emuToPercent(t),
				bottom: emuToPercent(b),
				right: emuToPercent(r),
				left: emuToPercent(l)
			};
		}

		return image;
	}

	getLine(data: ElementData, opt: ElementOption = {}) {
		const line: LineData = {
			...this.getBaseElement(data, { ...opt, type: 'line' }),
			type: 'line',
			name: data.nvCxnSpPr?.cNvPr.name || '',
			hidden: data.nvCxnSpPr?.cNvPr.hidden || false
		};

		if (data.spPr?.ln?.headEnd) {
			line.headEnd = {
				type: getLineEndType(data.spPr.ln.headEnd.type)
			};
		}
		if (data.spPr?.ln?.tailEnd) {
			line.tailEnd = {
				type: getLineEndType(data.spPr.ln.tailEnd.type)
			};
		}

		return line;
	}

	getShapeInfo(data: ElementData) {
		if (!data.spPr?.custGeom && !data.spPr?.prstGeom) return null;
		const shape: ShapeData = {
			type: getBaseShapeType(data.spPr?.prstGeom?.prst),
			path: []
		};

		if (data.spPr?.custGeom?.pathLst?.length) {
			shape.type = 'path';
			for (const item of data.spPr.custGeom.pathLst) {
				const { path, width, height } = getCustomShape(item);
				shape.path &&
					shape.path.push({
						ts: path,
						width,
						height
					});
			}
		}

		if (data?.spPr?.prstGeom?.avLst) {
			for (const item of data.spPr.prstGeom.avLst) {
				if (item.fmla) {
					const num = item.fmla.match(/-?\d+/g);
					const name = data.spPr.prstGeom.prst || '';
					if (item.name == 'adj') shape.radius = emuToPercent(Number(num));
					if (['arc'].includes(name)) {
						if (item.name == 'adj1') shape.startAngle = emuToAngle(Number(num)) - 360;
						if (item.name == 'adj2') shape.endAngle = emuToAngle(Number(num)) - 360;
					} else {
						if (item.name == 'adj1') shape.point1 = emuToPercent(Number(num));
						if (item.name == 'adj2') shape.point2 = emuToPercent(Number(num));
					}
					if (!['adj', 'adj1', 'adj2'].includes(item.name)) {
						if (!shape.otherParams) shape.otherParams = [];
						shape.otherParams.push({ name: item.name, value: item.fmla });
					}
				}
			}
		}

		return shape;
	}

	getColor(fill: Fill) {
		if (fill.color) {
			return this.getFill(fill.color);
		}
		if (fill.gsLst) {
			return this.getGradient(fill);
		}
		return undefined;
	}

	getFill(color: ColorData | string | any) {
		// if (typeof color == 'string') return color

		return getFill(color, {
			themeColorScheme: this.currentTheme
		});
	}

	getGradientColorStops(gsLst: Fill['gsLst']) {
		const list: Gradient['colorStops'] = (gsLst || [])
			.map((item) => {
				return {
					offset: emuToPercent(item.pos),
					color: this.getFill(item.color)
				};
			})
			.sort((a, b) => a.offset - b.offset);

		return list;
	}

	getGradient(fill: Fill): Gradient {
		const { gsLst, lin, path, tileRect = {}, rotWithShape } = structuredClone(fill);

		if (path?.path == 'circle' || path?.path == 'shape') {
			const { fillToRect = {} } = path;
			Object.keys(tileRect).forEach((item) => {
				const str = item as keyof typeof tileRect;
				tileRect[str] = emuToPercent(tileRect[str]);
			});

			Object.keys(fillToRect).forEach((item) => {
				const str = item as keyof typeof fillToRect;
				fillToRect[str] = emuToPercent(fillToRect[str]);
			});

			return {
				type: 'radial',
				radialPath: path.path,
				tileRect,
				fillToRect,
				gradientUnits: 'pixels',
				colorStops: this.getGradientColorStops(gsLst),
				rotWithShape
			};
		}

		const data: Gradient = {
			type: 'linear',
			gradientUnits: 'pixels',
			scaled: lin?.scaled || false,
			colorStops: this.getGradientColorStops(gsLst),
			angle: (lin?.ang || 0) / 60000,
			rotWithShape
		};

		return data;
	}

	getShadow(data: outerShdw, { width, height }: { width: number; height: number }): Shadow {
		const dir = emuToAngle(data.dir);
		const dist = emuToPixel(data.dist || 0);
		const blur = emuToPixel(data.blurRad || 0);
		const sx = emuToPercent(data.sx || 0);
		const sy = emuToPercent(data.sy || 0);

		return {
			color: this.getFill(data.color),
			algn: data.algn,
			sx,
			sy,
			kx: emuToPercent(data.kx || 0),
			ky: emuToPercent(data.ky || 0),
			rotWithShape: data.rotWithShape == true,
			dir,
			dist,
			blur: dist || blur,
			offsetX: Math.floor((dist - (sx ? width * (1 - sx) : 0)) * Math.cos((dir * Math.PI) / 180)),
			offsetY: Math.floor((dist - (sy ? height * (1 - sy) : 0)) * Math.sin((dir * Math.PI) / 180))
		};
	}
}
