import { Path, RectProps } from 'fabric';

import { Element } from '@/types/element';
import { getBaseCommonProps } from '../base';

export default async function getWedgeRectCallout(data: Element) {
	const option: Partial<RectProps> = await getBaseCommonProps(data);
	const { width, height } = data;

	const path = `
    M 0 0 
    L ${width} 0 
    L ${width} ${height} 
    L 0 ${height}
    Z`;

	return new Path(path, option);
}
