import { Polygon } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getRightArrow(data: Element) {
  const option = await getBaseCommonProps(data)
  const { width, height } = data
  const xRadius = width - Math.min(height, width) * (typeof data.shape?.point2 == 'number' ? data.shape.point2 : 0.5)
  const yRadius = height * (typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.5)

  return new Polygon([
    { x: 0, y: (height - yRadius) / 2 },
    { x: xRadius, y: (height - yRadius) / 2 },
    { x: xRadius, y: 0 },
    { x: width, y: height / 2 },
    { x: xRadius, y: height },
    { x: xRadius, y: (height - yRadius) / 2 + yRadius },
    { x: 0, y: (height - yRadius) / 2 + yRadius },
  ], option)
}