import { useRoutes, RouteObject, Outlet } from 'react-router-dom';

import Default from '@/layout/default';
import Layout from '@/layout';
import Home from '@/views/dashboard';

import MaterialList from '@/views/material/list';
import MaterialCate from '@/views/material/cate';

import SlidesList from '@/views/slides/list';
import SlidesCate from '@/views/slides/cate';

import TempList from '@/views/template/list';
import TempCate from '@/views/template/cate';

import ThemeList from '@/views/themes/list';
import ThemeCate from '@/views/themes/cate';

import ResourceColors from '@/views/resource/colors';
import ResourceFonts from '@/views/resource/fonts';
import ResourceHotWorks from '@/views/resource/hotWorks';

import Customer from '@/views/customer/list';

// 活动
import ActivityList from '@/views/activity/list';

import SystemManage from '@/views/system/manage';
import OrderList from '@/views/order';

import Editor from '@/views/editor';

import Login from '@/views/login';
import Error404 from '@/views/error/404';

const layoutRouter: Array<RouteObject> = [
	{
		index: true,
		path: '',
		element: <Home />
	},
	// 素材管理
	{
		path: 'material',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <MaterialList />
			},
			{
				path: 'cate',
				element: <MaterialCate />
			}
		]
	},
	// 版式管理
	{
		path: 'slides',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <SlidesList />
			},
			{
				path: 'cate',
				element: <SlidesCate />
			}
		]
	},
	// 模板管理
	{
		path: 'template',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <TempList />
			},
			{
				path: 'care',
				element: <TempCate />
			}
		]
	},
	// 主题管理
	{
		path: 'theme',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <ThemeList />
			},
			{
				path: 'care',
				element: <ThemeCate />
			}
		]
	},

	// 资源管理
	{
		path: 'resource',
		element: <Outlet />,
		children: [
			{
				path: 'colors',
				element: <ResourceColors />
			},
			{
				path: 'fonts',
				element: <ResourceFonts />
			},
			{
				path: 'hotWorks',
				element: <ResourceHotWorks />
			}
		]
	},

	// 用户管理
	{
		path: 'customer',
		element: <Customer />
	},
	// 订单管理
	{
		path: 'order',
		element: <OrderList />
	},
	// 活动管理
	{
		path: 'activity',
		element: <ActivityList />
	},
	// 系统管理
	{
		path: 'system',
		element: <Outlet />,
		children: [
			{
				path: 'manage',
				element: <SystemManage />
			}
		]
	}
];

const routes: Array<RouteObject> = [
	{
		path: '/',
		element: <Default />,
		children: [
			{
				path: '',
				element: <Layout />,
				children: layoutRouter
			},

			// 编辑器
			{
				path: 'editor',
				element: <Outlet />,
				children: [
					{
						path: 'slides',
						element: <Editor />
					},
					{
						path: 'slides/:id',
						element: <Editor />
					},
					{
						path: 'template',
						element: <Editor />
					},
					{
						path: 'template/:id',
						element: <Editor />
					}
				]
			},
			{
				path: '/404',
				element: <Error404 />
			}
		]
	},
	{
		path: 'login',
		element: <Login />
	}
];

const Router = () => {
	let element = useRoutes(routes);
	return element;
};

export default Router;
