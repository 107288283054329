import { createContext, ReactElement, useState, useLayoutEffect } from 'react';

import { getConfig } from '@/api';
import { getUserInfo } from '@/api/login';
import { getFontSymbol } from '@/api/fonts';

import { isLogin, setSessionStorage } from '@/utils';

export const GlobalContext = createContext<{
	cdnUrl: string;
	privateCdnUrl: string;
	userInfo: any;
	fontMap: { [key: string]: string };
	updateUserInfo: () => void;
}>({
	cdnUrl: '',
	privateCdnUrl: '',
	userInfo: null,
	fontMap: {},
	updateUserInfo: () => {}
});

export default ({ children }: { children: ReactElement }) => {
	const [cdnUrl, setCdnUrl] = useState<string>('');
	const [privateCdnUrl, setPrivateCdnUrl] = useState<string>('');
	const [userInfo, setUserInfo] = useState<any>(null);
	const [fontMap, setFontMap] = useState<{ [key: string]: string }>({});

	const updateUserInfo = async () => {
		if (isLogin()) {
			const res = await getUserInfo();
			setUserInfo(res.data);
		}
	};

	// cdn配置
	const getConfigSet = async () => {
		let res: any = await getConfig();
		if (res.code == '200') {
			setCdnUrl(res.data.cdn1);
			setSessionStorage('cdnUrl', res.data.cdn1);
			setPrivateCdnUrl(res.data.cdn2);
			setSessionStorage('privateCdnUrl', res.data.cdn2);
		}
	};

	// 字体库 map
	const getFontsMap = async () => {
		if (isLogin()) {
			let res = await getFontSymbol();
			setFontMap(res.data);
		}
	};

	useLayoutEffect(() => {
		getConfigSet();
		getFontsMap();
		updateUserInfo();
	}, []);

	return (
		<GlobalContext.Provider value={{ cdnUrl, privateCdnUrl, userInfo, fontMap, updateUserInfo }}>
			{children}
		</GlobalContext.Provider>
	);
};
