import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Form, Input, Space, Button, Popconfirm, Select, Tag, Message, Grid } from '@arco-design/web-react';
import { IconSearch, IconPlus } from '@arco-design/web-react/icon';

import {
	getTempList,
	delTemp,
	putTempState,
	putTempRecommend,
	getTempCateOptions,
	getTempColorOptions,
	putTempAiRecommend,
	putTempBeautifyRecommend
} from '@/api/template';
import { getFormatStyleOption } from '@/api/slides';

import { formatDate, setSessionStorage } from '@/utils';
import { GlobalContext } from '@/hooks/context';

import EditModal from './component/editModal';

export default () => {
	const navigate = useNavigate();
	const { cdnUrl } = useContext(GlobalContext);
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const columns: any = [
		{
			title: '缩略图',
			dataIndex: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => <img style={{ width: '80px' }} src={cdnUrl + record.preview} />
		},
		{
			title: '模板名称',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: '状态',
			dataIndex: 'status',
			width: 120,
			render: (_: any, record: any) => (
				<Space direction="vertical">
					{record.status == 1 && <Tag color="#00b42a">已启用</Tag>}
					{record.status == 2 && <Tag color="#86909c">已下架</Tag>}
					{record.recommended == 1 && <Tag color="#108ee9">已推荐</Tag>}
					{record.ai == 1 && <Tag color="cyan">已推荐AI</Tag>}
					{record.optimiez == 1 && <Tag color="#7bc616">已推荐美化</Tag>}
				</Space>
			)
		},
		{
			title: '模板类别',
			dataIndex: 'type'
		},
		{
			title: '模板风格',
			dataIndex: 'style'
		},
		{
			title: '页数',
			dataIndex: 'pages'
		},
		{
			title: '所需积分',
			dataIndex: 'price'
		},
		{
			title: '模板主题',
			dataIndex: 'color'
		},
		{
			title: '标签',
			dataIndex: 'tags'
		},
		{
			title: '提交人',
			dataIndex: 'creatorName'
		},
		{
			title: '创建时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 230,
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={() => {
							setSessionStorage('tempEditPreview', record.preview);
							navigate(`/editor/template/${record.id}`);
						}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此版式？" onOk={() => handleDel(record)}>
						<Button type="text">删除</Button>
					</Popconfirm>
					<Popconfirm
						position="left"
						title={`是否${record.status == 1 ? '下架' : '上架'}此版式？`}
						onOk={() => handleStatus(record)}>
						<Button type="text">{record.status == 1 ? '下架' : '上架'}</Button>
					</Popconfirm>
					<Popconfirm
						position="left"
						title={`是否${record.recommended == 1 ? '取消' : '设为'}推荐此模板？`}
						onOk={() => handleRecommend(record)}>
						<Button type="text">{record.recommended == 1 ? '取消' : '设为'}推荐</Button>
					</Popconfirm>
					<Popconfirm
						position="left"
						title={`是否${record.ai == 1 ? '取消' : '设为'}AI推荐此版式？`}
						onOk={() => handleAiRecommend(record)}>
						<Button type="text">{record.ai == 1 ? '取消' : '设为'}AI推荐</Button>
					</Popconfirm>
					<Popconfirm
						position="left"
						title={`是否${record.optimiez == 1 ? '取消' : '设为'}美化推荐此版式？`}
						onOk={() => handleBeautifyRecommend(record)}>
						<Button type="text">{record.optimiez == 1 ? '取消' : '设为'}美化推荐</Button>
					</Popconfirm>
					<Button
						type="text"
						onClick={() => {
							setShowModalId(record.id);
							setShowModal(true);
						}}>
						更换封面图
					</Button>
				</>
			)
		}
	];

	const [showModal, setShowModal] = useState(false);
	const [showModalId, setShowModalId] = useState('');

	const [styleList, setStyleList] = useState<any[]>([]);
	const [typeList, setTypeList] = useState<any[]>([]);
	const [colorOption, setColorOption] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};
	const handleSearch = () => {
		if (page == 1) {
			getList();
		} else {
			setPage(1);
		}
	};

	const getList = async () => {
		setLoading(true);
		const values = form.getFieldsValue();
		let res = await getTempList({
			...values,
			page,
			size: 10
		});

		res.data.list.forEach((it: any) => {
			if (it.preview.indexOf('?') > -1) {
				it.preview = it.preview + '&imageView2/1/w/160/h/90';
			} else {
				it.preview = it.preview + '?imageView2/1/w/160/h/90';
			}
		});

		setList(res.data.list);
		setTotal(res.data.total);
		setLoading(false);
	};

	// 模板类型
	const getTypeList = async () => {
		let res = await getTempCateOptions();
		if (res.code == 200) {
			setTypeList(res.data);
		}
	};
	// 模板风格
	const getStyleList = async () => {
		let res = await getFormatStyleOption();
		if (res.code == 200) {
			setStyleList(res.data);
		}
	};
	const getColorList = async () => {
		let res = await getTempColorOptions();
		setColorOption(res.data);
	};

	// 上下架
	const handleStatus = async (item: any) => {
		let status = item.status == 1 ? 2 : 1;
		let res = await putTempState(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};
	// 删除
	const handleDel = async (item: any) => {
		let res = await delTemp(item.id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};
	// 推荐
	const handleRecommend = async (item: any) => {
		let status = item.recommended == 1 ? 2 : 1;
		let res = await putTempRecommend(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};
	// AI推荐
	const handleAiRecommend = async (item: any) => {
		let status = item.ai == 1 ? 2 : 1;
		let res = await putTempAiRecommend(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};
	// 美化推荐
	const handleBeautifyRecommend = async (item: any) => {
		let status = item.optimiez == 1 ? 2 : 1;
		let res = await putTempBeautifyRecommend(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	useEffect(() => {
		getTypeList();
		getStyleList();
		getColorList();
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={6}>
							<FormItem field="type" label="类型">
								<Select allowClear placeholder="请选择模板类型" style={{ width: 200 }}>
									{typeList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="style" label="风格">
								<Select allowClear placeholder="请选择模板风格" style={{ width: 200 }}>
									{styleList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="color" label="色系">
								<Select allowClear placeholder="请选择色系" style={{ width: 200 }}>
									{colorOption.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="keywords" label="标签">
								<Input placeholder="请输入标签" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									<Button
										type="primary"
										icon={<IconPlus />}
										onClick={() => {
											navigate('/editor/template');
										}}>
										新增模板
									</Button>
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					pagination={{
						showTotal: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
			{/*--------*/}
			<EditModal id={showModalId} visible={showModal} setVisible={setShowModal} onCallback={getList} />
		</>
	);
};
