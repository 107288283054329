import fetch from '@/common/fetch';

export const getUploadKey = (data: any) =>
	fetch({
		url: '/res/v1/commons/upload/token',
		data: data,
		method: 'post'
	});

// 解析PPT
export const postAnalysisPPT = (data: any) =>
	fetch({
		url: '/res/v1/commons/analysis/pptx',
		data: data,
		method: 'post'
	});

// 版式评分价格指南
export const getFormatGradePrice = () =>
	fetch({
		url: '/res/v1/commons/config/guide/price/layout',
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
/**
 * 版式
 */
// 版式结构化列表
export const getFormatStructs = (type: string) =>
	fetch({
		url: `/res/v1/template/structs/${type}`,
		method: 'get'
	});
// 版式结构化详情
export const getFormatStructsDetail = (type: string, layoutId: string) =>
	fetch({
		url: `/res/v1/template/structs/${type}/${layoutId}`,
		method: 'get'
	});
// 获取绑定的结构化数据
export const getFormatBindStructs = (id: any) =>
	fetch({
		url: `/res/v1/template/layout/struct/data/${id}`,
		method: 'get'
	});

// 创建版式风格
export const postFormatStyle = (data: any) =>
	fetch({
		url: '/res/v1/template/style',
		data: data,
		method: 'post'
	});
// 版式风格分页列表
export const getFormatStyleList = (param: any) =>
	fetch({
		url: '/res/v1/template/style/list',
		params: param,
		method: 'get'
	});
// 版式风格列表
export const getFormatStyleOption = () =>
	fetch({
		url: '/res/v1/template/style/options',
		method: 'get'
	});
// 删除版式风格
export const delFormatStyle = (id: string | number) =>
	fetch({
		url: `/res/v1/template/style/${id}`,
		method: 'DELETE'
	});
// 编辑版式风格
export const putFormatStyle = (id: string, data: any) =>
	fetch({
		url: `/res/v1/template/style/${id}`,
		data: data,
		method: 'put'
	});

// 版式列表
export const getFormatList = (param: any) =>
	fetch({
		url: '/res/v1/template/layout/list',
		params: param,
		method: 'get'
	});
// 版式添加
export const postFormat = (data: any) =>
	fetch({
		url: '/res/v1/template/layout',
		data: data,
		method: 'post'
	});
// 版式删除
export const delFormat = (id: string | number) =>
	fetch({
		url: `/res/v1/template/layout/${id}`,
		method: 'DELETE'
	});
// 版式更新
export const putFormat = (id: string | number, data: any) =>
	fetch({
		url: `/res/v1/template/layout/${id}`,
		data: data,
		method: 'put'
	});
// 版式上下架
export const putFormatStatus = (type: string | number, id: string | number) =>
	fetch({
		url: `/res/v1/template/layout/state/${type}/${id}`,
		method: 'put'
	});
// 版式下载
export const getFormatDown = (id: string | number) =>
	fetch({
		url: `/res/v1/template/layout/pull/${id}`,
		method: 'get'
	});

/**
 * 母版
 */
export const getMasterList = (param: any) =>
	fetch({
		url: '/res/v1/template/master/list',
		params: param,
		method: 'get'
	});
export const postMaster = (data: any) =>
	fetch({
		url: '/res/v1/template/master',
		data: data,
		method: 'post'
	});
export const delMaster = (ids: string) =>
	fetch({
		url: `/res/v1/template/master/${ids}`,
		method: 'DELETE'
	});
export const putMaster = (id: string, data: any) =>
	fetch({
		url: `/res/v1/template/master/${id}`,
		data: data,
		method: 'put'
	});
export const putMasterStatus = (type: string | number, id: string) =>
	fetch({
		url: `/res/v1/template/master/state/${type}/${id}`,
		method: 'put'
	});
export const getMasterDown = (id: string) =>
	fetch({
		url: `/res/v1/template/master/pull/${id}`,
		method: 'get'
	});
