import { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Message } from '@arco-design/web-react';

import { postHotWork, putHotWork } from '@/api/hotWork';

interface IProps {
	visible: boolean;
	info: any;
	onOk: () => void;
	onCancel: () => void;
}

export default (props: IProps) => {
	const { visible, info, onOk, onCancel } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;

	const [loading, setLoading] = useState(false);

	const handleSave = async () => {
		try {
			await form.validate();
			setLoading(true);
			const value = await form.getFieldsValue();
			if (info?.id) {
				const res = await putHotWork(info.id, value);
				setLoading(false);
				if (res.code === 200) {
					Message.success('修改成功');
					onOk();
					onCancel();
				}
			} else {
				const res = await postHotWork(value);
				setLoading(false);
				if (res.code === 200) {
					Message.success('添加成功');
					onOk();
					onCancel();
				}
			}
		} catch (e) {}
	};

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				words: info?.words || ''
			});
		} else {
			form.resetFields();
		}
	}, [visible]);

	return (
		<Modal title={info?.id ? '编辑热词' : '新增热词'} footer={null} visible={visible} onCancel={onCancel}>
			<Form form={form} autoComplete="off">
				<FormItem label="AI热词" field="words" rules={[{ required: true, message: '请输入内容' }]}>
					<Input placeholder="请输入内容" />
				</FormItem>
				<FormItem wrapperCol={{ offset: 5 }}>
					<Button type="primary" loading={loading} style={{ marginRight: 24 }} onClick={handleSave}>
						提交
					</Button>
					<Button
						style={{ marginRight: 24 }}
						onClick={() => {
							form.resetFields();
						}}>
						重置
					</Button>
				</FormItem>
			</Form>
		</Modal>
	);
};
