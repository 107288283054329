export const viewportSize = {
	width: 1280,
	height: 720,
	type: 'custom'
};
export const layoutList = [
	{
		id: '1',
		name: 'l0',
		layoutName: '空白',
		elements: []
	}
];
export const themeMap = {
	t0: {
		name: 'Office 主题​​',
		color: {
			dk1: 'rgba(0,0,0,1)',
			lt1: 'rgba(255,255,255,1)',
			dk2: 'rgba(14,40,65,1)',
			lt2: 'rgba(232,232,232,1)',
			accent1: 'rgba(21,96,130,1)',
			accent2: 'rgba(233,113,50,1)',
			accent3: 'rgba(25,107,36,1)',
			accent4: 'rgba(15,158,213,1)',
			accent5: 'rgba(160,43,147,1)',
			accent6: 'rgba(78,167,46,1)',
			hlink: 'rgba(70,120,134,1)',
			folHlink: 'rgba(150,96,125,1)',
			name: 'Office'
		}
	}
};
export const masterList = [
	{
		id: '959681',
		themeName: 't0',
		layouts: ['l0'],
		elements: [],
		colorMap: {
			bg1: 'rgba(255,255,255,1)',
			tx1: 'rgba(0,0,0,1)',
			bg2: 'rgba(232,232,232,1)',
			tx2: 'rgba(14,40,65,1)',
			accent1: 'rgba(21,96,130,1)',
			accent2: 'rgba(233,113,50,1)',
			accent3: 'rgba(25,107,36,1)',
			accent4: 'rgba(15,158,213,1)',
			accent5: 'rgba(160,43,147,1)',
			accent6: 'rgba(78,167,46,1)',
			hlink: 'rgba(70,120,134,1)',
			folHlink: 'rgba(150,96,125,1)'
		},
		background: 'rgba(255,255,255,1)'
	}
];
export const defaultStyles = {
	defPPr: {},
	lvl1pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96
	},
	lvl2pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 48
	},
	lvl3pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 96
	},
	lvl4pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 144
	},
	lvl5pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 192
	},
	lvl6pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 240
	},
	lvl7pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 288
	},
	lvl8pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 336
	},
	lvl9pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 384
	}
};
