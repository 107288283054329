import request from '@/common/fetch';

/**
 * 模板列表
 */
// 列表
export const getTempList = (param: any) =>
	request({
		url: '/res/v1/template/list',
		method: 'get',
		params: param,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 添加
export const postTempAdd = (data: any) =>
	request({
		url: '/res/v1/template/',
		data: data,
		method: 'post'
	});
// 编辑
export const putTempEdit = (id: any, data: any) =>
	request({
		url: `/res/v1/template/${id}`,
		data: data,
		method: 'put'
	});
// 删除
export const delTemp = (id: any) =>
	request({
		url: `/res/v1/template/${id}`,
		method: 'delete',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 详情
export const getTempDetail = (id: any) =>
	request({
		url: `/res/v1/template/${id}`,
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 上下架
export const putTempState = (id: any, type: any) =>
	request({
		url: `/res/v1/template/state/${type}/${id}`,
		method: 'put',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 推荐
export const putTempRecommend = (id: any, type: any) =>
	request({
		url: `/res/v1/template/rec/${type}/${id}`,
		method: 'put',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// AI推荐
export const putTempAiRecommend = (id: any, type: any) =>
	request({
		url: `/res/v1/template/rec/ai/${type}/${id}`,
		method: 'put',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 美化推荐
export const putTempBeautifyRecommend = (id: any, type: any) =>
	request({
		url: `/res/v1/template/rec/optimize/${type}/${id}`,
		method: 'put',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
/**
 * 模板类型
 */
// 列表
export const getTempCateList = (param?: any) =>
	request({
		url: '/res/v1/template/type/list',
		method: 'get',
		params: param,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 下拉列表
export const getTempCateOptions = () =>
	request({
		url: '/res/v1/template/type/options',
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 添加
export const postTempCate = (data: any) =>
	request({
		url: '/res/v1/template/type',
		data: data,
		method: 'post'
	});
// 编辑
export const putTempCateEdit = (id: any, data: any) =>
	request({
		url: `/res/v1/template/type/${id}`,
		data: data,
		method: 'put'
	});
// 删除
export const delTempCate = (id: any) =>
	request({
		url: `/res/v1/template/type/${id}`,
		method: 'delete',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});

/**
 * 模板颜色
 */
// 列表
export const getTempColorList = (param?: any) =>
	request({
		url: '/res/v1/template/color/list',
		method: 'get',
		params: param,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 下拉列表
export const getTempColorOptions = () =>
	request({
		url: '/res/v1/template/color/options',
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 添加
export const postTempColor = (data: any) =>
	request({
		url: '/res/v1/template/color',
		data: data,
		method: 'post'
	});
// 编辑
export const putTempColorEdit = (id: any, data: any) =>
	request({
		url: `/res/v1/template/color/${id}`,
		data: data,
		method: 'put'
	});
// 删除
export const delTempColor = (id: any) =>
	request({
		url: `/res/v1/template/color/${id}`,
		method: 'delete',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
