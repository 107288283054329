import fetch from '@/common/fetch';

export const getUploadKey = (data: any) =>
	fetch({
		url: '/res/v1/commons/upload/token',
		data: data,
		method: 'post'
	});

// 解析PPT
export const postAnalysisPPT = (data: any) =>
	fetch({
		url: '/res/v1/commons/analysis/pptx',
		data: data,
		method: 'post'
	});
// 配置项
export const getConfig = () =>
	fetch({
		url: '/common/config',
		method: 'GET'
	});
