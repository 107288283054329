import { FabricObject, Group } from 'fabric'

import { GroupData, Element } from '@/types/element'
import { GetElementOption } from '../types'
import getText from './text'
import getImage from './image'
import getLine from './line'

export async function getElement(data: Element, option: GetElementOption = {}): Promise<FabricObject | undefined | null> {
  const { group } = option
  const newData = structuredClone(data)
  if (newData.type == 'group') return await getGroup(newData, option)
  // 默认塞入群组背景色
  if (group?.length && !newData.background) {
    group.forEach(item => {
      if (item.background) newData.background = item.background
    })
  }
  if (group?.length && newData.type == 'text') {
    group.forEach(_group => {
      newData.text.forEach(item => {
        item.text.forEach(it => {
          it.fontSize /= _group.scaleX
        })
      })
    })
  }
  if (newData.type == 'text') return await getText(newData)
  if (newData.type == 'image') return await getImage(newData, option)
  if (newData.type == 'line') return await getLine(newData)
}

export default async function getGroup(data: GroupData, { onSelect, group: groupData, ...args }: GetElementOption) {
  const nodes: FabricObject[] = []
  for (const child of data.elements) {
    const node = await getElement(child, { onSelect, group: groupData ? [...groupData, data] : [data], ...args })
    if (node) {
      if (child.type != 'group') {
        onSelect && node.on('mousedblclick', () => {
          onSelect(child, node)
        })
      }
      nodes.push(node)
    }
  }
  const group = new Group(nodes, {
    subTargetCheck: true,
    flipX: data.flipX,
    flipY: data.flipY,
    left: data.left,
    top: data.top,
  })
  group.set({
    left: data.left,
    top: data.top,
    // width: data.width / data.scaleX,
    // height: nodes.reduce((total, cur) => {
    //   if (total < cur.height) return cur.height
    //   return total
    // }, data.height) / data.scaleY,
    scaleX: data.scaleX,
    scaleY: data.scaleY,
  })
  if (data.rot) {
    group.rotate(data.rot)
  }
  return group
}