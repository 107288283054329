import { Ellipse, EllipseProps } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getEllipse(data: Element) {
  const option: Partial<EllipseProps> = await getBaseCommonProps(data)
  option.rx = data.width / 2
  option.ry = data.height / 2

  return new Ellipse(option)
}