import { Ellipse, EllipseProps, Group  } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getDonut(data: Element) {
  const option: Partial<EllipseProps> = await getBaseCommonProps(data)
  option.rx = data.width / 2
  option.ry = data.height / 2
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25

  const outEllipse = new Ellipse(option)

  let innerRx = data.width * (0.5 - radius)
  let innerRy = data.height * (0.5 - radius)

  if (data.width < data.height) {
    innerRy = Math.max(innerRy, option.ry - (option.rx - innerRx))
  } else {
    innerRx = Math.max(innerRx, option.rx - (option.ry - innerRy))
  }

  const innerEllipse = new Ellipse({
    ...option,
    rx: innerRx,
    ry: innerRy,
    left: option.rx - innerRx,
    top: option.ry - innerRy,
    fill: 'transparent',
    inverted: true,
  })

  outEllipse.clipPath = innerEllipse

  const nodes = [outEllipse]

  if (data.border?.color) {
    const borderEllipse = await innerEllipse.clone()
    nodes.push(borderEllipse)
  }

  innerEllipse.set({ left: -innerEllipse.width / 2, top: -innerEllipse.height / 2 })

  const group = new Group(nodes, {
    ...option,
  })

  return group
}