import { useNavigate } from 'react-router-dom';
import { Menu } from '@arco-design/web-react';
import {
	IconHome,
	IconCalendar,
	IconPalette,
	IconInteraction,
	IconUser,
	IconSettings,
	IconGift,
	IconOrderedList
} from '@arco-design/web-react/icon';

export default (props: any) => {
	const { activeKey = '/' } = props;
	const navigate = useNavigate();
	const MenuItem = Menu.Item;
	const SubMenu = Menu.SubMenu;

	return (
		<Menu
			theme="dark"
			// autoOpen
			selectedKeys={[activeKey]}
			onClickMenuItem={(key) => navigate(key)}
			style={{ width: '100%' }}>
			<MenuItem key="/">
				<IconHome />
				首页
			</MenuItem>
			{/* <SubMenu
				key="/material"
				title={
					<span>
						<IconImage />
						素材管理
					</span>
				}>
				<MenuItem key="/material/list">素材列表</MenuItem>
				<MenuItem key="/material/cate">素材类别</MenuItem>
			</SubMenu> */}
			<SubMenu
				key="/slides"
				title={
					<span>
						<IconInteraction />
						版式管理
					</span>
				}>
				<MenuItem key="/slides/list">版式列表</MenuItem>
				<MenuItem key="/slides/cate">版式风格管理</MenuItem>
			</SubMenu>
			<SubMenu
				key="/template"
				title={
					<span>
						<IconCalendar />
						模板管理
					</span>
				}>
				<MenuItem key="/template/list">模板列表</MenuItem>
				<MenuItem key="/template/care">模板类型</MenuItem>
			</SubMenu>
			<SubMenu
				key="/theme"
				title={
					<span>
						<IconPalette />
						主题管理
					</span>
				}>
				<MenuItem key="/theme/list">主题列表</MenuItem>
				<MenuItem key="/theme/care">主题类型</MenuItem>
			</SubMenu>
			<SubMenu
				key="/resource"
				title={
					<span>
						<IconSettings />
						资源管理
					</span>
				}>
				<MenuItem key="/resource/colors">色系管理</MenuItem>
				<MenuItem key="/resource/fonts">字体管理</MenuItem>
				<MenuItem key="/resource/hotWorks">AI热词管理</MenuItem>
			</SubMenu>
			<MenuItem key="/customer">
				<IconUser />
				客户管理
			</MenuItem>
			<MenuItem key="/order">
				<IconOrderedList />
				订单管理
			</MenuItem>
			<MenuItem key="/activity">
				<IconGift />
				活动管理
			</MenuItem>
			<SubMenu
				key="/system"
				title={
					<span>
						<IconSettings />
						系统管理
					</span>
				}>
				<MenuItem key="/system/manage">管理员</MenuItem>
				<MenuItem key="/system/order">订单管理</MenuItem>
			</SubMenu>
		</Menu>
	);
};
