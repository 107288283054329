import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Button, InputNumber, InputTag, Select, Message } from '@arco-design/web-react';
import { postTempAdd } from '@/api/template';
import { getSessionStorage } from '@/utils';

interface IProps {
	visible: boolean;
	pds: string[];
	thumb: string;
	name: string;
	style: string;
	tags: string[];
	typeList: any[];
	colosList: any[];
	styleList: any[];
}

export default (props: IProps) => {
	const { visible, pds, thumb, name, style, tags, typeList, colosList, styleList } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const navigator = useNavigate();
	const cdnUrl = getSessionStorage('cdnUrl');

	const onSaveTemp = async (v: any) => {
		v.price = v.price * 100;
		let res: any = await postTempAdd({
			...v,
			preview: thumb,
			pds: pds.join('#')
		});
		if (res.code == 200) {
			Message.success('模板保存成功');
			navigator('/template/list');
		}
	};

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				name,
				style,
				tags
			});
		}
	}, [visible]);

	return (
		<Modal
			title="创建模板"
			style={{ width: '480px' }}
			visible={visible}
			footer={null}
			autoFocus={false}
			focusLock={true}
			closable={false}>
			<Form form={form} autoComplete="off" onSubmit={onSaveTemp}>
				<FormItem label="封面图">
					<img src={cdnUrl + thumb + '?imageView2/1/w/160/h/90'} style={{ width: '140px', height: 'auto' }} />
				</FormItem>
				<FormItem label="模板名称" field="name" rules={[{ required: true }]}>
					<Input placeholder="请输入模板名称" />
				</FormItem>
				<FormItem label="模板风格" field="style" rules={[{ required: true }]}>
					<Select options={styleList} placeholder="请选择模板风格" />
				</FormItem>
				<FormItem label="行业类型" field="type" rules={[{ required: true }]}>
					<Select options={typeList} placeholder="请选择行业类型" />
				</FormItem>
				<FormItem label="模板色系" field="color" rules={[{ required: true }]}>
					<Select options={colosList} placeholder="请选择模板色系" />
				</FormItem>
				<FormItem label="模板标签" field="tags" rules={[{ required: true }]}>
					<InputTag allowClear placeholder="请输入模板标签，按回车键" />
				</FormItem>
				<FormItem label="模板定价" field="price" rules={[{ required: true }]}>
					<InputNumber placeholder="请输入定价" />
				</FormItem>

				<FormItem wrapperCol={{ offset: 4 }}>
					<Button type="primary" htmlType="submit" style={{ marginRight: 24 }}>
						保存
					</Button>
					<Button>取消</Button>
				</FormItem>
			</Form>
		</Modal>
	);
};
