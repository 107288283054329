import { useEffect, useState } from 'react';
import { Table, Space, Button, Popconfirm, Tag, Message } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import { formatDate } from '@/utils';

import { getActivityList, delActivity, putActivityStatus } from '@/api/activity';

import InfoModal from './modal';

export default () => {
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 80
		},
		{
			title: '活动名称',
			dataIndex: 'name'
		},
		// {
		// 	title: '活动类型',
		// 	dataIndex: 'type'
		// },
		{
			title: '可参与的用户类型',
			width: 160,
			dataIndex: 'allowedUserType',
			render: (_: any, record: any) => (
				<>
					{record.allowedUserType == 'NORMAL' && '普通用户'}
					{record.allowedUserType == 'AGENT' && '代理商用户'}
					{record.allowedUserType == 'DESIGNER' && '设计师'}
					{record.allowedUserType == 'ALL' && '所有用户'}
				</>
			)
		},
		{
			title: '起止时间',
			width: 250,
			key: 'startTime',
			render: (_: any, record: any) => (
				<>
					{formatDate(record.startTime, 'YYYY-MM-DD')} - {formatDate(record.endTime, 'YYYY-MM-DD')}
				</>
			)
		},
		{
			title: '状态',
			key: 'status',
			render: (_: any, record: any) => (
				<>
					{record.status == 0 && <Tag color="#86909c">待上架</Tag>}
					{record.status == 20 && <Tag color="#00b42a">已发布</Tag>}
					{record.status == 30 && <Tag color="#86909c">已下架</Tag>}
					{record.status == 40 && <Tag color="#86909c">已结束</Tag>}
				</>
			)
		},
		{
			title: '创建人',
			dataIndex: 'creatorName'
		},
		{
			title: '操作',
			width: 300,
			fixed: 'right',
			key: 'action',
			render: (_: any, record: any) => (
				<Space>
					<Button type="text" onClick={() => handleEdit(record, true)}>
						查看
					</Button>

					{record.status == 0 && (
						<>
							<Button type="text" onClick={() => handleEdit(record, false)}>
								编辑
							</Button>
							<Popconfirm position="left" title="是否要上架此活动？" onOk={() => handleStatus(record.id, 1)}>
								<Button type="text">上架</Button>
							</Popconfirm>
						</>
					)}
					{record.status == 20 && (
						<Popconfirm position="left" title="是否要下架此活动？" onOk={() => handleStatus(record.id, 2)}>
							<Button type="text">下架</Button>
						</Popconfirm>
					)}

					{record.status != 20 && (
						<Popconfirm position="left" title="是否要删除此活动？" onOk={() => handleDel(record)}>
							<Button type="text">删除</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [modalInfo, setModalInfo] = useState<any>(null);
	const [isShowDetail, setShowDetail] = useState(false);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getActivityList({
			page,
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	const handleEdit = (item: any, isDetail?: boolean) => {
		setModalInfo(item);
		setShowModal(true);
		setShowDetail(isDetail ? true : false);
	};

	// 上下架
	const handleStatus = async (id: number, status: number) => {
		try {
			let res = await putActivityStatus(id, status);
			if (res.code == 200) {
				getList();
			}
		} catch (error: any) {
			console.log('error', error);
			Message.error(error.data.msg || '操作失败');
		}
	};
	// 删除
	const handleDel = async (item: any) => {
		let res = await delActivity(item.id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button
					type="primary"
					icon={<IconPlus />}
					onClick={() => {
						setModalInfo(null);
						setShowModal(true);
						setShowDetail(false);
					}}>
					新增活动
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
			<InfoModal
				visible={showModal}
				isDetail={isShowDetail}
				info={modalInfo}
				onCancel={() => setShowModal(false)}
				onOk={() => getList()}
			/>
		</>
	);
};
