import Cookies from 'js-cookie';
import dayjs from 'dayjs';

const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;

//
export const isLogin = () => {
	return !!getCookies(tokenName);
};
// localStorage
export const getStorage = (key: string) => {
	return localStorage.getItem(key);
};
export const setStorage = (key: string, token: string) => {
	localStorage.setItem(key, token);
};
export const delStorage = (key: string) => {
	localStorage.removeItem(key);
};
// SessionStorage
export const getSessionStorage = (key: string) => {
	return sessionStorage.getItem(key);
};
export const setSessionStorage = (key: string, token: string) => {
	sessionStorage.setItem(key, token);
};
export const delSessionStorage = (key: string) => {
	sessionStorage.removeItem(key);
};
// cookies
export const setCookies = (key: string, value: string, expires: number) => {
	let nowDate: any = new Date();
	let exp = new Date(nowDate * 1 + expires * 1000);

	Cookies.set(key, value, {
		expires: exp
	});
};
export const getCookies = (key: string) => {
	return Cookies.get(key);
};
export const delCookies = (key: string) => {
	Cookies.remove(key);
};

//
export const formatDate = (stamp: number | string, format = 'YYYY-MM-DD HH:mm:ss') => {
	return dayjs(stamp).format(format);
};
