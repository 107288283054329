import { useEffect, useState } from 'react';
import { Table, Space, Button, Popconfirm, ColorPicker, Message } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';

// postTempColor, putTempColorEdit,
import { getTempColorList, delTempColor } from '@/api/template';

import { formatDate } from '@/utils';

export default () => {
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: '颜色名称',
			dataIndex: 'name'
		},
		{
			title: '色值',
			dataIndex: 'color',
			render: (_: any, record: any) => (
				<>
					<ColorPicker defaultValue={record.color} disabled />
				</>
			)
		},
		{
			title: '创建时间',
			key: 'createTime',
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '修改时间',
			key: 'updateTime',
			render: (_: any, record: any) => <>{formatDate(record.updateTime)}</>
		},
		{
			title: '操作',
			width: 160,
			key: 'action',
			render: (_: any, record: any) => (
				<Space>
					<Button type="text" onClick={() => {}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此风格？" onOk={() => handleDel(record)}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getTempColorList({
			page,
			size: 20
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	// const handleShowEdit = (item: any) => {
	// formInfo.id = item.id
	// formInfo.name = item.name
	// isShow.value = true
	// };

	const handleDel = async (id: any) => {
		let res = await delTempColor(id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button type="primary" icon={<IconPlus />} onClick={() => {}}>
					创建色系
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						total: total,
						pageSize: 20,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>
		</>
	);
};
