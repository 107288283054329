import fetch from '@/common/fetch';

export const getUserList = (param: any) =>
	fetch({
		url: '/user/v1/users/list',
		params: param,
		method: 'get'
	});

export const postRechargeAi = (data: any) =>
	fetch({
		url: '/user/v1/users/recharge/ai',
		method: 'post',
		data
	});
