import { Line } from 'fabric';

import { LineData } from '@/types/element';
import { getBaseCommonProps } from '../helper';

export default async function getLine(data: LineData) {
	const option = await getBaseCommonProps(data);
	const borderWidth: number = data.border?.width || 0;
	// const rot = Math.round(Math.atan(data.height / data.width) * 180 / Math.PI)
	// const radius = rot == 90 ? data.height : data.width / Math.cos(((2 * Math.PI) / 360) * rot)
	const { top, left, width, height } = data;

	const line = new Line([left, top, left + width, top + height], {
		...option,
		left: left - borderWidth / 2,
		top: top - borderWidth / 2
	});
	// const group = new Group([line], option)

	// line.set({ left: -line.width / 2, top: - line.height / 2 })

	// const rect = new Rect({
	//   ...option,
	//   strokeWidth: 0,
	//   fill: option.stroke,
	//   width: Math.max(group.width, borderWidth, 10),
	//   height: Math.max(group.height, borderWidth),
	//   // top: data.top - (rot ? triangleSize - borderWidth : 0)
	// })

	// // group.set({ left: -2, top: -group.height / 2 })
	// console.log(rect.width, rect.height, borderWidth, group.width, group.left, group.top, borderWidth)

	// rect.set({ clipPath: group })
	if (data.rot) {
		line.rotate(data.rot);
	}

	return line;

	// const line = new Line([left, top, left + (rot == 90 ? 0 : radius), top + (rot == 90 ? radius : 0)], option)
	// let headTriangle: Polygon | null = null
	// let tailTriangle: Polygon | null = null

	// const group = new Group([line])
	// if (data.headEnd && data.headEnd.type == 'triangle') {
	//   headTriangle = new Polygon([
	//     { x: left, y: top - borderWidth / 2 },
	//     { x: left + triangleSize, y: top - borderWidth / 2 - triangleSize/ 2 },
	//     { x: left + triangleSize, y: top - borderWidth / 2 + triangleSize / 2 },
	//   ], {
	//     strokeWidth: 0,
	//   })
	//   group.add(headTriangle)
	// }
	// if (data.tailEnd && data.tailEnd.type == 'triangle') {
	//   tailTriangle = new Polygon([
	//     { x: left + width - triangleSize, y: top - borderWidth / 2 - triangleSize / 2 },
	//     { x: left + width, y: top - borderWidth / 2 },
	//     { x: left + width - triangleSize, y: top - borderWidth / 2 + triangleSize / 2 },
	//   ], {
	//     strokeWidth: 0,
	//   })
	//   group.add(tailTriangle)
	// }
	// const rectHeight = (option.height || triangleSize) + (rot ? (triangleSize - borderWidth) : 0)
	// group.set({ left: left + (data.width - radius + borderWidth) / 2, top, width, height: height || borderWidth })
	// group.set({
	//   left: -data.width / 2,
	//   top: -(height || borderWidth) / 2,
	//   width,
	//   height: height || borderWidth
	// })

	// if (headTriangle) {
	//   headTriangle.set({
	//     left: -(data.width) / 2,
	//     top: -(headTriangle.height) / 2
	//   })
	// }
	// if (tailTriangle) {
	//   tailTriangle.set({
	//     left: radius / 2 - triangleSize,
	//     top: -tailTriangle.height / 2
	//   })
	// }
	// const lineWidth = radius - (data.headEnd ? triangleSize : 0) - (data.tailEnd ? triangleSize : 0)
	// line.set({
	//   width: lineWidth,
	//   left: -(data.width + borderWidth) / 2 + (headTriangle ? triangleSize : 0),
	//   top: -(line.height || borderWidth) / 2,
	// })

	// if (rot) group.rotate(rot)

	// const rect = new Rect({
	//   ...option,
	//   strokeWidth: 0,
	//   fill: option.stroke,
	//   height: rectHeight,
	//   top: data.top - (rot ? triangleSize - borderWidth : 0)
	// })

	// rect.set({ clipPath: group })

	// return rect
}
