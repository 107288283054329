import { useState, useContext } from 'react';
import { Modal, Message } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import uploadFile, { typeEnum } from '@/utils/uploadFile';
import { GlobalContext } from '@/hooks/context';

import { putTempEdit } from '@/api/template';

import './editModal.less';

export default (props: any) => {
	const { id, visible, setVisible, onCallback } = props;

	const { cdnUrl } = useContext(GlobalContext);

	const [loading, setLoading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState('');

	// 上传图片
	const handleUpPreview = async (e: Event) => {
		try {
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			if (file) {
				const info: any = await uploadFile(file, typeEnum.preview);
				setPreviewUrl(info.data.key);
			}
		} catch (error: any) {
			console.log(error);
			Message.error(error?.msg || error);
		}
	};

	const handleSave = async () => {
		setLoading(true);
		const res = await putTempEdit(id, {
			preview: previewUrl
		});
		setLoading(false);
		if (res.code == 200) {
			Message.success('更新成功');
			onCallback();
			hamdleCancel();
		}
	};
	const hamdleCancel = () => {
		setVisible(false);
		setPreviewUrl('');
		setLoading(false);
	};
	return (
		<Modal
			wrapClassName="edit-template-modal"
			title="更新封面图"
			visible={visible}
			confirmLoading={loading}
			onOk={handleSave}
			onCancel={hamdleCancel}
			autoFocus={false}
			focusLock={true}
			style={{ width: '360px' }}>
			<div className="edit-template-btn">
				<input className="upload-file" type="file" onChange={(e: any) => handleUpPreview(e)} name="" id="" />
				{previewUrl ? (
					<img src={cdnUrl + previewUrl + '?imageView2/1/w/480/h/270'} alt="" />
				) : (
					<span>
						<IconPlus />
						上传图片
					</span>
				)}
			</div>
		</Modal>
	);
};
