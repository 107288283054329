import { ShapeType, Element } from '@/types/element'
import getPath from './path'
import getRect from './rect'
import getRoundRect from './roundRect'
import getFlowChartTerminator from './flowChartTerminator'
import getEllipse from './ellipse'
import getArc from './arc'
import getTriangle from './triangle'
import getFlowChartExtract from './flowChartExtract'
import getFlowChartMerge from './flowChartMerge'
import getUpArrow from './upArrow'
import getDownArrow from './downArrow'
import getLeftArrow from './leftArrow'
import getRightArrow from './rightArrow'
import getHomePlate from './homePlate'
import getHexagon from './hexagon'
import getParallelogram from './parallelogram'
import getTrapezoid from './trapezoid'
import getDiamond from './diamond'
import getPentagon from './pentagon'
import getDonut from './donut'
import getChevron from './chevron'
import getStar5 from './star5'
import getRound2DiagRect from './round2DiagRect'
import getRound2SameRect from './round2SameRect'
import getRound1Rect from './round1Rect'
import getSnip1Rect from './snip1Rect'
import getSnip2SameRect from './snip2SameRect'
import getSnip2DiagRect from './snip2DiagRect'
import getSnipRoundRect from './snipRoundRect'
import getMathPlus from './mathPlus'
import getMathMinus from './mathMinus'
import getMathMultiply from './mathMultiply'
import getPlus from './plus'
import getCorner from './corner'
import getDiagStripe from './diagStripe'
import getWedgeRectCallout from './wedgeRectCallout'
import getRtTriangle from './rtTriangle'

export default async function getShape(type: ShapeType, data: Element) {
  switch (type) {
  case 'path':
    return await getPath(data)
  case 'rect':
    return await getRect(data)
  case 'roundRect':
    return await getRoundRect(data)
  case 'flowChartTerminator':
    return await getFlowChartTerminator(data)
  case 'ellipse':
    return await getEllipse(data)
  case 'arc':
    return await getArc(data)
  case 'triangle':
    return await getTriangle(data)
  case 'flowChartExtract':
    return await getFlowChartExtract(data)
  case 'flowChartMerge':
    return await getFlowChartMerge(data)
  case 'rtTriangle':
    return await getRtTriangle(data)
  case 'upArrow':
    return await getUpArrow(data)
  case 'downArrow':
    return await getDownArrow(data)
  case 'leftArrow':
    return await getLeftArrow(data)
  case 'rightArrow':
    return await getRightArrow(data)
  case 'homePlate':
    return await getHomePlate(data)
  case 'hexagon':
    return await getHexagon(data)
  case 'parallelogram':
    return await getParallelogram(data)
  case 'trapezoid':
    return await getTrapezoid(data)
  case 'diamond':
    return await getDiamond(data)
  case 'pentagon':
    return await getPentagon(data)
  case 'donut':
    return await getDonut(data)
  case 'chevron':
    return await getChevron(data)
  case 'star5':
    return await getStar5(data)
  case 'round2DiagRect':
    return await getRound2DiagRect(data)
  case 'round2SameRect':
    return await getRound2SameRect(data)
  case 'round1Rect':
    return await getRound1Rect(data)
  case 'snip1Rect':
    return await getSnip1Rect(data)
  case 'snip2SameRect':
    return await getSnip2SameRect(data)
  case 'snip2DiagRect':
    return await getSnip2DiagRect(data)
  case 'snipRoundRect':
    return await getSnipRoundRect(data)
  case 'mathPlus':
    return await getMathPlus(data)
  case 'mathMinus':
    return await getMathMinus(data)
  case 'mathMultiply':
    return await getMathMultiply(data)
  case 'plus':
    return await getPlus(data)
  case 'corner':
    return await getCorner(data)
  case 'diagStripe':
    return await getDiagStripe(data)
  case 'wedgeRectCallout':
    return await getWedgeRectCallout(data)
  default:
    return null
  }
}