import { Polygon } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getTrapezoid(data: Element) {
  const option = await getBaseCommonProps(data)
  const { width, height } = data
  const xRadius = Math.min(width, height) * (typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25)

  return new Polygon([
    { x: xRadius, y: 0 },
    { x: width - xRadius, y: 0 },
    { x: width, y: height },
    { x: 0, y: height },
  ], option)
}