import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Table, Form, Input, Space, Button, Avatar, Tag, Grid, DatePicker } from '@arco-design/web-react';
import { IconSearch, IconUser } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';

import { getUserList } from '@/api/user';

import { formatDate } from '@/utils';

import RechargeAi from './component/rechargeAi';

export default () => {
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const { cdnUrl } = useContext(GlobalContext);
	const { RangePicker } = DatePicker;

	const columns: any = [
		{
			title: '会员ID',
			dataIndex: 'id',
			fixed: 'left',
			width: 120
		},
		{
			title: '头像',
			key: 'avatar',
			// fixed: 'left',
			width: 100,
			render: (_: any, record: any) => (
				<Avatar size={64}>{record?.avatar ? <img alt="avatar" src={cdnUrl + record?.avatar} /> : <IconUser />}</Avatar>
			)
		},
		{
			title: '昵称',
			key: 'nickName',
			// fixed: 'left',
			width: 240,
			render: (_: any, record: any) => <span>{record.nickName}</span>
		},
		{
			title: '手机号码',
			dataIndex: 'mobile',
			width: 140
		},
		{
			title: '可用AI次数',
			dataIndex: 'ai',
			width: 140,
			render: (_: any, record: any) => (
				<span>
					<strong style={{ color: '#f53f3f' }}>{record.ai}</strong>次
				</span>
			)
		},
		// {
		// 	title: 'vip到期时间',
		// 	dataIndex: 'vipExpireTime',
		// 	render: (_: any, record: any) => <span>{record.vipExpireTime > 0 ? formatDate(record.vipExpireTime) : '-'}</span>
		// },
		{
			title: '最近登录',
			key: 'lastLoginIp',
			width: 220,
			render: (_: any, record: any) => (
				<div>
					<div>IP：{record.lastLoginIp || '-'}</div>
					<div>时间：{record.lastLoginTime > 0 ? formatDate(record.lastLoginTime) : '-'}</div>
				</div>
			)
		},

		{
			title: '状态',
			key: 'state',
			width: 80,
			render: (_: any, record: any) => (
				<>{record.state == 1 ? <Tag color="#00b42a">正常</Tag> : <Tag color="#86909c">锁定</Tag>}</>
			)
		},
		{
			title: '注册时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			width: 130,
			fixed: 'right',
			key: 'action',
			render: (_: any, record: any) => (
				<Space>
					<Button
						type="text"
						onClick={() => {
							setUserInfo(record);
							setVisible(true);
						}}>
						添加AI次数
					</Button>
				</Space>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	// 弹窗
	const [visible, setVisible] = useState(false);
	const [userInfo, setUserInfo] = useState(null);

	const onPageChange = (page: number) => {
		setPage(page);
	};
	const handleSearch = () => {
		if (page == 1) {
			getList();
		} else {
			setPage(1);
		}
	};

	const getList = async () => {
		setLoading(true);
		const values = form.getFieldsValue();

		if (values.rangeTimes && values.rangeTimes.length == 2) {
			values.startTime = new Date(values.rangeTimes[0] + ' 00:00:00').getTime();
			values.endTime = new Date(values.rangeTimes[1] + ' 23:59:59').getTime();
			delete values.rangeTimes;
		}
		if (values.loginTimes && values.loginTimes.length == 2) {
			values.loginStartTime = new Date(values.loginTimes[0] + ' 00:00:00').getTime();
			values.loginEndTime = new Date(values.loginTimes[1] + ' 23:59:59').getTime();
			delete values.loginTimes;
		}

		let res = await getUserList({
			...values,
			page,
			size: 10
		});
		setLoading(false);
		res.data.list.forEach((it: any) => {
			if (it.avatar) {
				it.avatar += it.avatar.indexOf('?') > -1 ? '&' : '?';
				it.avatar += 'imageView2/1/w/100/h/100';
			}
		});

		setList(res.data.list);
		setTotal(res.data.total);
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={8}>
							<FormItem field="id" label="用户ID">
								<Input allowClear placeholder="请输入用户ID" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="mobile" label="手机号">
								<Input allowClear placeholder="请输入手机号码" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="rangeTimes" label="注册时间">
								<RangePicker
									allowClear
									shortcuts={[
										{
											text: '近7天',
											value: () => [dayjs(), dayjs().subtract(1, 'week')]
										},
										{
											text: '近30天',
											value: () => [dayjs(), dayjs().subtract(1, 'month')]
										},
										{
											text: '近1个季度',
											value: () => [dayjs(), dayjs().subtract(3, 'month')]
										},
										{
											text: '近1年',
											value: () => [dayjs(), dayjs().subtract(1, 'year')]
										}
									]}
								/>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="loginTimes" label="登录时间">
								<RangePicker
									allowClear
									shortcuts={[
										{
											text: '近7天',
											value: () => [dayjs(), dayjs().subtract(1, 'week')]
										},
										{
											text: '近30天',
											value: () => [dayjs(), dayjs().subtract(1, 'month')]
										},
										{
											text: '近1个季度',
											value: () => [dayjs(), dayjs().subtract(3, 'month')]
										},
										{
											text: '近1年',
											value: () => [dayjs(), dayjs().subtract(1, 'year')]
										}
									]}
								/>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem>
								<Button type="primary" icon={<IconSearch />} htmlType="submit">
									搜索
								</Button>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>

			<RechargeAi visible={visible} info={userInfo} onOk={getList} onClose={() => setVisible(false)} />
		</>
	);
};
