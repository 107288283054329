import { FabricImage, Group, ImageProps } from 'fabric'

import { ImageData } from '@/types/element'
import { getShape, getBaseCommonProps } from '../helper'
import { GetElementOption } from '../types'

export default async function getImage(data: ImageData, { cdnPrefix }: GetElementOption) {
  const options: Partial<ImageProps> = {
    ...await getBaseCommonProps(data),
    centeredRotation: true,
    opacity: data.opacity,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    flipX: false,
    flipY: false,
  }

  const image = await FabricImage.fromURL(data.src.match(/^http/) ? data.src : cdnPrefix + data.src, {
    crossOrigin: 'anonymous',
  }, options)
  const { left = 0, right = 0, top = 0, bottom = 0} = data.rect || {}
  const clipWidth = image.width * (data.rect ? 1 - left - right : 1)
  const clipHeight = image.height * (data.rect ? 1 - top - bottom : 1)
  const scaleX = data.width / (image.width * (data.rect ? 1 - left - right : 1))
  const scaleY = data.height / (image.height * (data.rect ? 1 - top - bottom : 1))

  if (data.shape) {
    const clipPath = await getShape(data.shape.type, {
      ...data,
      width: clipWidth,
      height: clipHeight,
      left: -image.width / 2 + image.width * (data.rect ? left : 0),
      top: -image.height / 2 + image.height * (data.rect ? top : 0),
      flipX: false,
      flipY: false,
      background: data.background || '#000',
    })

    if (clipPath) {
      image.clipPath = clipPath
    }
  }

  image.scaleX = scaleX
  image.scaleY = scaleY

  const group = new Group([image], {
    flipX: data.flipX,
    flipY: data.flipY,
  })

  group.set({ width: data.width, height: data.height, left: data.left, top: data.top })

  image.left = -data.width / 2 - image.width * (data.rect?.left || 0) * image.scaleX
  image.top = -data.height / 2 - image.height * (data.rect?.top || 0) * image.scaleY

  if (data.rot) {
    group.rotate(data.rot)
  }

  return group
}