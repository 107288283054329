import { forwardRef, useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react'
import { Canvas, CanvasOptions, FabricObject, StaticCanvas } from 'fabric'

import { SlideData, ViewportSize, Element } from '@/types/element'
import useElementList, { UseElementListProps } from './hook/element'
import useCanvas from './hook/canvas'
import { getColorOrGradient } from './helper'

export interface SlideProps {
  data: SlideData
  layoutList?: SlideData[]
  viewportSize?: ViewportSize
  onSelect?(data: Element): void
  editable?: boolean
  scale?: number
  onRendered?(canvas: Canvas | StaticCanvas, elements: FabricObject[], data: SlideData): void
  cdnPrefix?: string
}

export default forwardRef(function Slide({
  data,
  viewportSize,
  layoutList,
  onSelect,
  editable = true,
  scale = 1,
  onRendered,
  cdnPrefix = 'https://cdn.gaippt.com/',
}: SlideProps, ref) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [select, setSelect] = useState<[Element, FabricObject | undefined] | []>([])
  const layout = useMemo(() => (layoutList || []).find(item => item.name == data.layoutRef), [data, layoutList])
  const elementList = useMemo(() => [
    ...(layout?.elements || []).map(item => ({ ...item, selectable: false })),
    ...data.elements
  ], [data, layout])
  const canvasOption = useMemo<Partial<CanvasOptions>>(() => {
    const params: Partial<CanvasOptions> = {
      ...viewportSize,
    }
    const background = data.background || layout?.background
    if (background) {
      params.backgroundColor = getColorOrGradient(background, {
        width: viewportSize?.width || 1280,
        height: viewportSize?.height || 720,
      })
    }
    return params
  }, [viewportSize, data, layout])
  const canvas = useCanvas({
    canvas: canvasRef.current,
    editable,
    option: canvasOption,
  })
  const onSelectEvent = useCallback<NonNullable<UseElementListProps['onSelect']>>((data, event) => {
    setSelect([data, event])
  }, [])

  const { elements } = useElementList({
    list: elementList,
    onSelect: onSelectEvent,
    cdnPrefix,
  })

  useEffect(() => {
    if (canvas && editable) {
      const currentCanvas = canvas as Canvas
      if (select[0]) {
        onSelect?.(select[0])
      }
      // group中元素选中
      if (select[1]) {
        currentCanvas.setActiveObject(select[1])
        currentCanvas.renderAll()
      }
    }
  }, [select, canvas, onSelect, editable])

  useEffect(() => {
    if (canvas && elements.length) {
      elements.forEach(node => {
        canvas.add(node)
      })
      onRendered?.(canvas, elements, data)
    }
  }, [canvas, elements, onRendered, data])

  useImperativeHandle(ref, () => ({
    canvas,
    canvasRef: canvasRef.current,
  }))

  return <div style={{ zoom: scale }}>
    <canvas ref={canvasRef} style={{ border: '1px solid #000' }} />
  </div>
})
