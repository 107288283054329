import { useCallback, useEffect, useState } from 'react'
import { FabricObject } from 'fabric'

import { Element } from '@/types/element'
import { getElement } from '../Element/group'

export interface UseElementListProps {
  list: Element[]
  onSelect?(data: Element, event?: FabricObject): void
  cdnPrefix?: string
}

export default function useElementList({ list, onSelect, cdnPrefix }: UseElementListProps) {
  const [elements, setElements] = useState<FabricObject[]>([])

  const getElementList = useCallback(async () => {
    const l: FabricObject[] = []
    for (const element of list) {
      if (element.hidden) continue
      const node = await getElement(element, { onSelect, cdnPrefix })
      if (node) {
        node.selectable = typeof element.selectable != 'undefined' ? element.selectable : true
        l.push(node)
        onSelect && node.on('selected', () => {
          onSelect(element, node)
        })
      }
    }
    setElements(l)
  }, [list, onSelect, cdnPrefix])

  useEffect(() => {
    getElementList()
    return () => {
      setElements([])
    }
  }, [getElementList])

  return {
    elements
  }
}