import { Polygon } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getUpArrow(data: Element) {
  const option = await getBaseCommonProps(data)
  const { width, height } = data
  const radius = Math.max(height, width)
  const xRadius = width * (typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.5)
  let yRadius = Math.min(height, width) * (typeof data.shape?.point2 == 'number' ? data.shape.point2 : 0.5)
  if (radius - yRadius < 2) yRadius = radius

  return new Polygon([
    { x: 0, y: yRadius },
    { x: width / 2, y: 0 },
    { x: width, y: yRadius },
    { x: (width - xRadius) / 2 + xRadius, y: yRadius },
    { x: (width - xRadius) / 2 + xRadius, y: height },
    { x: (width - xRadius) / 2, y: height },
    { x: (width - xRadius) / 2, y: yRadius },
  ], option)
}