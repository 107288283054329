import { useEffect, useState } from 'react';
import { Table, Space, Button, Popconfirm, Message, Tag } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';

import { getFontList, delFont } from '@/api/fonts';

import { formatDate, getSessionStorage } from '@/utils';

// import EditFontModal from '@/components/editFontModal';

export default () => {
	const cdnUrl = getSessionStorage('cdnUrl');
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 100,
			fixed: 'left'
		},
		{
			title: '字体名称',
			dataIndex: 'name',
			fixed: 'left',
			width: 140
		},
		{
			title: '中文fontFamily',
			dataIndex: 'symbolCn'
		},
		{
			title: '英文fontFamily',
			dataIndex: 'symbolEn'
		},
		{
			title: '预览',
			dataIndex: 'preview',
			render: (_: any, record: any) => (
				<div
					style={{
						width: '115px',
						height: '25px',
						backgroundSize: 'cover',
						backgroundImage: `url(${cdnUrl + record.preview}?v=6)`
					}}></div>
			)
		},
		{
			title: '状态',
			dataIndex: 'status',
			render: (_: any, record: any) => (
				<>
					{record.status == 0 && <Tag color="#86909c">已禁用</Tag>}
					{record.status == 1 && <Tag color="#00b42a">已启用</Tag>}
				</>
			)
		},
		{
			title: '修改人',
			key: 'modifier'
		},
		{
			title: '修改时间',
			key: 'updateTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.updateTime)}</>
		},
		{
			title: '操作',
			width: 240,
			key: 'action',
			fixed: 'right',
			render: (_: any, record: any) => (
				<Space>
					<Button type="text" onClick={() => {}}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此风格？" onOk={() => {}}>
						<Button type="text">{record.status == 1 ? '禁用' : '启用'}</Button>
					</Popconfirm>
					<Popconfirm position="left" title="是否要删除此风格？" onOk={() => handleDel(record.id)}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	// const [showEditModal, setShowEditModal] = useState(false);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getFontList({
			page,
			size: 20
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	// const handleShowEdit = () => {
	// formInfo.id = item.id
	// formInfo.name = item.name
	// isShow.value = true
	// };
	// const handleStatus = async (id: number | string) => {};
	const handleDel = async (id: number | string) => {
		let res = await delFont(id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button type="primary" icon={<IconPlus />} onClick={() => {}}>
					添加字体
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					scroll={{
						x: 1300
					}}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						total: total,
						pageSize: 20,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>

			{/* <EditFontModal visible={showEditModal} onCancel={() => setShowEditModal(false)} /> */}
		</>
	);
};
