import { useEffect, useState } from 'react'
import { Canvas, CanvasOptions, StaticCanvas } from 'fabric'

interface UseCanvasProps {
  canvas?: HTMLCanvasElement | null,
  editable: boolean,
  option: Partial<CanvasOptions>,
}

export default function useCanvas({
  canvas: canvasDom,
  editable = true,
  option,
}: UseCanvasProps) {
  const [canvas, setCanvas] = useState<Canvas | StaticCanvas | null>(null)

  useEffect(() => {
    let canvas: Canvas | StaticCanvas
    if (canvasDom) {
      if (editable) {
        canvas = new Canvas(canvasDom, {
          ...option,
          enableRetinaScaling: true,
          preserveObjectStacking: true,
        })
      } else {
        canvas = new StaticCanvas(canvasDom, option)
      }
      setCanvas(canvas)
    }
    return () => {
      if (canvas) canvas.dispose()
    }
  }, [canvasDom, editable, option])

  useEffect(() => {
    const editCanvas = canvas as Canvas

    const deleteListener = (e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        const activeObjects = editCanvas.getActiveObjects()
        editCanvas.discardActiveObject()
        if (activeObjects.length) {
          editCanvas.remove(...activeObjects)
        }
      }
    }

    const bodyListener: EventListener = (e) => {
      if (canvas && e.target !== editCanvas.upperCanvasEl) {
        editCanvas.discardActiveObject()
        canvas.renderAll()
      }
    }

    if (canvas && editable) {
      window.addEventListener('keydown', deleteListener)
      window.addEventListener('click', bodyListener)
    }

    return () => {
      canvas && canvas.dispose()

      if (editable) {
        window.removeEventListener('keydown', deleteListener)
        window.removeEventListener('click', bodyListener)
      }
    }
  }, [editable, canvas, canvasDom])

  return canvas
}