import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from '@arco-design/web-react';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import Router from './routers';
import GlobalContext from '@/hooks/context';

const App = () => {
	return (
		<ConfigProvider locale={zhCN}>
			<GlobalContext>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</GlobalContext>
		</ConfigProvider>
	);
};

export default App;
