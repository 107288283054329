import { Polygon } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getRtTriangle(data: Element) {
  const option = await getBaseCommonProps(data)

  return new Polygon([
    { x: 0, y: 0 },
    { x: data.width, y: data.height },
    { x: 0, y: data.height },
  ], option)
}