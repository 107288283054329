import * as qiniu from 'qiniu-js';
// import * as qiniu from 'qiniu-js/src/index'
import { getUploadKey } from '@/api';

const bucketName = import.meta.env.VITE_APP_BUCKET_NAME;

export enum typeEnum {
	pptx = 'pptx',
	image = 'image',
	preview = 'preview',
	typeface = 'typeface'
}

const uploadQiNiu = (file: any, data: any) => {
	return new Promise((resolve, reject) => {
		let config = {
			useCdnDomain: true,
			region: qiniu.region.cnEast2,
			bucket: bucketName
		};
		let putExtra = {
			customVars: {}
		};
		const observable = qiniu.upload(file, data.key, data.token, putExtra, config);
		// 开始上传qq
		observable.subscribe({
			next: (_result) => {
				// console.log('result', result);
			},
			error: () => {
				reject();
			},
			complete: (res) => {
				// console.log('complete', res);
				resolve(res);
			}
		});
	});
};

export default async (file: File, type: typeEnum) => {
	let tokenInfo: any = await getUploadKey({
		fileName: file.name,
		fileSize: file.size,
		type: type
	});
	let info: any = await uploadQiNiu(file, tokenInfo.data);
	return info;
};
