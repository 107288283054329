import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, NavigateFunction } from 'react-router-dom';

import { isLogin, delSessionStorage, delCookies } from '@/utils';

export let customNavigate: NavigateFunction;

export default () => {
	const location = useLocation();
	const navigate = useNavigate();
	customNavigate = navigate;

	const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;
	const RefTokenName = import.meta.env.VITE_APP_REFRESH_TOKEN_NAME;

	useEffect(() => {
		let path = location.pathname;
		if (path.indexOf('/login') == -1 && !isLogin()) {
			delSessionStorage(RefTokenName);
			delCookies(tokenName);
			navigate('/login');
		}
	}, [location]);

	return <Outlet />;
};
