import fetch from '@/common/fetch';

// 列表
export const getActivityList = (param: any) =>
	fetch({
		url: '/common/v1/activities/list',
		params: param,
		method: 'get'
	});
// 详情
export const getActivityDetail = (id: string | number) =>
	fetch({
		url: `/common/v1/activities/${id}`,
		method: 'get'
	});
// 添加
export const addActivity = (data: any) =>
	fetch({
		url: '/common/v1/activities/',
		data,
		method: 'post'
	});

// 修改
export const putActivityEdit = (id: string, data: any) =>
	fetch({
		url: `/common/v1/activities/${id}`,
		data,
		method: 'put'
	});

// 删除
export const delActivity = (id: string | number) =>
	fetch({
		url: `/common/v1/activities/${id}`,
		method: 'delete'
	});

// 上下架
export const putActivityStatus = (id: string | number, type: number) =>
	fetch({
		url: `/common/v1/activities/state/${type}/${id}`,
		method: 'put'
	});
