import { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { customAlphabet } from 'nanoid';
import {
	Button,
	Form,
	Input,
	InputTag,
	Select,
	Space,
	Modal,
	Progress,
	Message,
	InputNumber,
	Spin
} from '@arco-design/web-react';
import { IconLeft, IconDelete } from '@arco-design/web-react/icon';

import StructureBind from './component/structureBind';
import SelectSlide from './component/selectSlide';
import AddTempModal from './component/addTempModal';

import SlideComponent from '@/components/Slide';
import PptParse from '@/utils/PptParse';
import { SlideData, ViewportSize } from '@/types/element';

import { GlobalContext } from '@/hooks/context';

import { postAnalysisPPT } from '@/api';
import { getFormatStyleOption, postFormat, putFormat } from '@/api/slides';
import { getTempCateOptions, getTempColorOptions, putTempEdit, getTempDetail } from '@/api/template';

import { getSessionStorage } from '@/utils';
import { addFontFamily } from '@/utils/common';
import uploadFile, { typeEnum } from '@/utils/uploadFile';

import * as defaultJson from '@/common/default';

// import XmlJson from './test.json';

import './index.less';

export default () => {
	const cdnUrl = getSessionStorage('cdnUrl');

	const { fontMap } = useContext(GlobalContext);

	const nanoid = customAlphabet('1234567890', 6);
	const navigate = useNavigate();
	const location = useLocation();
	const { id }: any = useParams();

	const [form] = Form.useForm();
	const FormItem = Form.Item;

	const [loading, setLoading] = useState(false);

	// 编辑
	const [editorType, setEditorType] = useState<any>('slide');
	const [editInfo, setEditInfo] = useState<any>({});
	const [saveLoad, setSaveLoad] = useState<boolean>(false);

	// 每页 ppt ref集合
	const slideRefMap = useRef<any>({});
	const curSlideRelMap = useRef<any>(null);

	// ppt 数据相关
	const [slideList, setSlideList] = useState<SlideData[]>([]);
	const [layoutList, setLayoutList] = useState<SlideData[]>([]);
	const [viewportSize, setViewportSize] = useState<ViewportSize>();

	// const [themeMap, setThemeMap] = useState<Theme>();
	// const [masterList, setMasterList] = useState<SlideData[]>([]);
	// const [defaultStyles, setDefaultStyles] = useState<{ [k: string]: DefaultStyle }>({});

	const [fonts, setFonts] = useState<string[]>([]);

	// 缩放
	const [scale, setScale] = useState<number>(1);

	// 相关类型接口数据
	const [styleList, setStyleList] = useState<any>([]);
	const [typeList, setTypeList] = useState([]);
	const [colosList, setColosList] = useState([]);

	// 绑定相关
	const [relBindList, setRelBindList] = useState<any>({});

	// 当前操作的幻灯片
	const [curSlideId, setCurSlideId] = useState<string>('');
	const [slideIdx, setSlideIdx] = useState(0);
	const [curSlide, setCurSlide] = useState<any>(null);

	// 提交进度相关
	const [showSchedule, setShowSchedule] = useState(false);
	const [percent, setPercent] = useState(100);

	// 创建模板相关
	const [tempBindRel, setTempBindRel] = useState<any>({});
	const [showCreateTemp, setShowCreateTemp] = useState(false);
	const [createTempInfo, setCreateTempInfo] = useState<any>({
		pds: [],
		thumb: '',
		name: '',
		style: '',
		tags: [],
		typeList,
		colosList,
		styleList
	});

	// 模板风格
	const getStyleList = async () => {
		let res = await getFormatStyleOption();
		setStyleList(res.data);
	};
	// 模板类型
	const getTypeList = async () => {
		let res: any = await getTempCateOptions();
		if (res.code == 200) {
			setTypeList(res.data);
		}
	};
	// 模板颜色
	const getColorList = async () => {
		let res = await getTempColorOptions();
		setColosList(res.data);
	};

	// 上传 ppt 源文件
	const handleUpPpt = async (e: Event) => {
		try {
			setLoading(true);
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			let info: any = await uploadFile(file, typeEnum.pptx);

			let res = await postAnalysisPPT({
				key: info.data.key
			});
			let pptInfo: any = await axios.get(res.data.url);
			const parse = new PptParse();

			const { slideList, viewportSize, layoutList, fontList } = parse.getData(pptInfo.data);

			const offsetWidth = document.body.offsetWidth - 490;
			const scale = offsetWidth / viewportSize.width;
			setScale(scale >= 1 ? 1 : scale);

			// 加载字体
			for (let i = 0; i < fontList.length; i++) {
				let key = fontList[i];

				if (fontMap[key]) {
					try {
						await addFontFamily(key, cdnUrl + fontMap[key]);
					} catch (error) {}
				}
			}

			setSlideList(slideList);
			setLayoutList(layoutList);
			setViewportSize(viewportSize);
			// setThemeMap(themeMap);
			// setMasterList(masterList);
			// setDefaultStyles(defaultStyles);
			setFonts(fontList);

			setCurSlideId(slideList[0].id);
			setSlideIdx(0);
			setCurSlide(slideList[0]);

			setLoading(false);
		} catch (error: any) {
			console.log(error);
			Message.error(error?.msg || error);
			setLoading(false);
		}
	};

	// 绑定关系回调
	const onRelBindCb = useCallback(
		(data: any, catalogueNum?: number | null) => {
			console.log('bind::-1', catalogueNum, curSlideId, data);
			relBindList[curSlideId] = data;
			setRelBindList({ ...relBindList });
			// if (catalogueNum) {
			// 	relCatalogNumObj[curSlide?.id] = catalogueNum;
			// 	setRelCatalogNumObj({ ...relCatalogNumObj });
			// }
		},
		[curSlideId]
	);
	// 图片替换回调
	const onRelImgCb = useCallback(
		(elementId: number | string, imgUrl: string) => {
			setCurSlide(null);
			let curSlide: any = null;
			slideList.forEach((it) => {
				if (it.id == curSlideId) {
					if (it.elements) {
						it.elements.forEach((val: any) => {
							if (val.elements) {
								it.elements.forEach((a: any) => {
									if (a.id == elementId) {
										a.src = imgUrl;
									}
								});
							} else {
								if (val.id == elementId) {
									val.src = imgUrl;
								}
							}
						});
					}

					curSlide = it;
				}
			});
			setSlideList([...slideList]);

			console.log('sfsf-s', curSlideRelMap.current.canvas.renderAll);

			const timer = setTimeout(() => {
				setCurSlide(curSlide);
				clearTimeout(timer);
			}, 200);

			// curSlideRelMap.current.canvas.renderAll();
		},
		[curSlideId]
	);

	// 上传缩略图到七牛云
	const handleUpThumb = async (slideId: number | string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let curSlideRef = slideRefMap.current[slideId];
				console.log('curSlideRef', curSlideRef);
				curSlideRef?.canvasRef.toBlob(
					async function (blob: any) {
						let file = new window.File([blob], 'thumb.png', { type: 'image/png' });
						console.log(`file-${slideId}`, file);
						let info: any = await uploadFile(file, typeEnum.preview);
						resolve(info);
					},
					'image/png',
					0.7
				);
			} catch (error) {
				console.log('handleUpThumb-ree', error);
				reject();
			}
		});
	};

	// 保存
	const handleSave = async (values: any) => {
		if (editorType == 'slide') {
			if (id) {
				setSaveLoad(true);
				await onSaveSlidesEdit(values);
			} else {
				await onSaveSlides(values);
			}
		}
		if (editorType == 'template') {
			setSaveLoad(true);
			await onSaveTemplate(values);
		}
	};
	const onSaveSlidesEdit = async (values: any) => {
		try {
			let rawData = {
				slides: slideList[0],
				fonts
			};

			let formData = {
				preview: editInfo.preview,
				rawData: JSON.stringify(rawData),
				pd: [relBindList[curSlideId]],
				...values
			};

			// // 上传图片
			// let thumbUrl: any = await handleUpThumb(0);
			// formData.preview = thumbUrl.data.key;

			let info: any = await putFormat(id, formData);
			if (info.code == 200) {
				Message.success('更新成功');
				navigate('/slides/list');
			}
			setSaveLoad(false);
		} catch (error: any) {
			setSaveLoad(false);
			Message.error(error?.msg || error);
		}
	};
	const onSaveSlides = async (values: any) => {
		try {
			// 保存主题
			// const themeData = await addTheme({
			// 	name: theme.name,
			// 	...theme.color
			// });
			// console.log('themeData::', themeData);

			setPercent(0);
			setShowSchedule(true);

			const slidePds: string[] = [],
				slideThumb: string[] = [];

			for (let i = 0; i < slideList.length; i++) {
				let slide = slideList[i];
				let rawData = {
					slides: slide,
					fonts
				};
				const pds = relBindList[slide.id];
				console.log(slide.id, pds);

				if (pds) {
					let formData = {
						preview: '',
						rawData: JSON.stringify(rawData),
						pd: [pds],
						...values,
						name: values.name + '-' + (i + 1),
						price: 0,
						score: 0
					};

					console.log('formData', formData);

					// 上传图片
					let thumbUrl: any = await handleUpThumb(slide.id);
					formData.preview = thumbUrl.data.key;
					const res = await postFormat(formData);

					slidePds.push(`${slide.id}@${res.data}`);
					slideThumb.push(formData.preview);
				}
				const percent = Math.floor(((i + 1) / slideList.length) * 100);
				setPercent(percent);

				if (i == slideList.length - 1) {
					setCreateTempInfo({
						pds: slidePds,
						thumb: slideThumb[0],
						name: values.name,
						style: values.style,
						tags: values.tags,
						typeList,
						colosList,
						styleList
					});
				}
			}
		} catch (error: any) {
			Message.error(error?.msg || error);
			setShowSchedule(false);
			setPercent(0);
		}
	};
	const onSaveTemplate = async (v: any) => {
		try {
			v.price = v.price * 100;
			let pds: any = [];
			let slideIds = slideList.map((it: any) => it.id);

			console.log('slideIds', slideIds);
			console.log('tempBindRel', Object.keys(tempBindRel));

			slideIds.forEach((id: any) => {
				if (tempBindRel[id]) {
					pds.push(`${id}@${tempBindRel[id]}`);
				}
			});

			const previewStr: any = getSessionStorage('tempEditPreview');
			const previewArr = previewStr.split('?');

			console.log('previewArr', previewArr);

			let res: any = await putTempEdit(id, {
				...v,
				preview: previewArr[0],
				pds: pds.join('#')
			});
			if (res.code == 200) {
				Message.success('模板保存成功');
				navigate('/template/list');
			}
			setSaveLoad(false);
		} catch (error: any) {
			setSaveLoad(false);
			Message.error(error?.msg || error);
		}
	};

	// 选择模板回调
	const onSelectCb = async (item: any) => {
		let { status, data } = await axios.get(item.rawData);
		console.log('data::', data);
		if (status == 200) {
			// 加载字体
			for (let i = 0; i < data.fonts.length; i++) {
				let key = data.fonts[i];

				if (fontMap[key]) {
					await addFontFamily(key, cdnUrl + fontMap[key]);
				}
			}
			const newFont = fonts.concat(data.fonts);
			const fontList = Array.from(new Set(newFont));

			const slideId = nanoid();

			const slide = {
				...data.slides
				// id: slideId
			};

			tempBindRel[slide.id] = item.id;

			console.log('tempBindRel', slideId, tempBindRel);

			// slideList.push(slide);
			setSlideList([...slideList, slide]);
			setTempBindRel({ ...tempBindRel });
			setFonts(fontList);
		}
	};
	// 删除页面
	const onDelSlideItem = async (idx: number) => {
		slideList.splice(idx, 1);
		setSlideList([...slideList]);
		if (slideIdx == idx) {
			setCurSlide(null);
			let index = 0;
			if (idx == 0) {
			} else {
				index = idx - 1;
			}
			setSlideIdx(index);
			setCurSlideId(slideList[index].id);
			const times = setTimeout(() => {
				setCurSlide(slideList[index]);
				clearTimeout(times);
			}, 200);
		}
	};

	/**
	 * 编辑
	 */
	const getSlideInfo = async () => {
		try {
			let info: any = getSessionStorage('slideEdit');
			let slideInfo = JSON.parse(info);

			let { data, status } = await axios.get(slideInfo.rawData);
			console.log('data', data);
			if (status == 200) {
				const { slides, fonts } = data;
				form.setFieldsValue({
					name: slideInfo.name,
					style: slideInfo.styleId,
					tags: slideInfo.tags
				});

				const offsetWidth = document.body.offsetWidth - 490;
				const scale = offsetWidth / defaultJson.viewportSize.width;
				setScale(scale >= 1 ? 1 : scale);

				setEditInfo(slideInfo);

				// 加载字体
				for (let i = 0; i < fonts.length; i++) {
					let key = fonts[i];

					if (fontMap[key]) {
						await addFontFamily(key, cdnUrl + fontMap[key]);
					}
				}

				setSlideList([slides]);
				setLayoutList(defaultJson.layoutList);
				setViewportSize(defaultJson.viewportSize);
				// setThemeMap(defaultJson.themeMap);
				// setMasterList(defaultJson.masterList);
				// setDefaultStyles(defaultJson.defaultStyles);

				setCurSlideId(slides.id);
				setSlideIdx(0);
				setCurSlide(slides);
			}
		} catch (error) {}
	};
	const getTempInfo = async () => {
		try {
			let { code, data }: any = await getTempDetail(id);
			if (code == 200) {
				const { name, style, color, slides, tags, type, price } = data;

				let slideList = [],
					bindRel: any = {},
					fontList: string[] = [];

				const ids = slides.map((it: any) => it.id);

				console.log('slideIds', JSON.stringify(ids));

				for (let i = 0; i < slides.length; i++) {
					let item = slides[i];

					let raw = item.layout.rawData;
					let pptInfo: any = await axios.get(raw);

					bindRel[item.id] = item.layout.id;
					slideList.push(pptInfo.data.slides);
					fontList = fontList.concat(pptInfo.data.fonts);
				}

				// 加载字体
				const fonts = Array.from(new Set(fontList));
				console.log('fonts', fonts);
				for (let i = 0; i < fonts.length; i++) {
					let key = fonts[i];
					if (fontMap[key]) {
						await addFontFamily(key, cdnUrl + fontMap[key]);
					}
				}

				form.setFieldsValue({
					name,
					style,
					type,
					color,
					tags: [tags],
					price: price / 100
				});

				const offsetWidth = document.body.offsetWidth - 490;
				const scale = offsetWidth / defaultJson.viewportSize.width;
				setScale(scale >= 1 ? 1 : scale);

				// console.log('bindRel', bindRel);

				setTempBindRel(bindRel);

				console.log('slideList', slideList);

				setSlideList(slideList);
				setLayoutList(defaultJson.layoutList);
				setViewportSize(defaultJson.viewportSize);

				setCurSlideId(slideList[0].id);
				setSlideIdx(0);
				setCurSlide(slideList[0]);
			}
		} catch (error) {}
	};

	// ----本地测试数据------
	// const renderTest = async () => {
	// 	const { fonts, slides } = XmlJson;

	// 	for (let i = 0; i < fonts.length; i++) {
	// 		let key = fonts[i];
	// 		if (fontMap[key]) {
	// 			await addFontFamily(key, cdnUrl + fontMap[key]);
	// 		}
	// 	}
	// 	setSlideList([slides]);

	// 	setLayoutList(defaultJson.layoutList);
	// 	setViewportSize(defaultJson.viewportSize);

	// 	setFonts(fonts);

	// 	// 缩放比例
	// 	const offsetWidth = document.body.offsetWidth - 490;
	// 	const scale = offsetWidth / defaultJson.viewportSize.width;
	// 	setScale(scale >= 1 ? 1 : scale);

	// 	setCurSlideId(slides.id);
	// 	setSlideIdx(0);
	// 	setCurSlide(slides);
	// };
	// const localDate = async () => {
	// 	const parse = new PptParse();
	// 	const res = parse.getData(XmlJson);
	// 	const { slideList, viewportSize, layoutList, themeMap, masterList, defaultStyles, fontList } = res;

	// 	let id = slideList[0].id;

	// 	for (let i = 0; i < fontList.length; i++) {
	// 		let key = fontList[i];
	// 		if (fontMap[key]) {
	// 			await addFontFamily(key, cdnUrl + fontMap[key]);
	// 		}
	// 	}

	// 	console.log('slideList', slideList);
	// 	setSlideList(slideList);

	// 	setLayoutList(layoutList);
	// 	setViewportSize(viewportSize);
	// 	// setThemeMap(themeMap);
	// 	// setMasterList(masterList);
	// 	// setDefaultStyles(defaultStyles);

	// 	setFonts(fontList);

	// 	// 缩放比例
	// 	const offsetWidth = document.body.offsetWidth - 490;
	// 	const scale = offsetWidth / viewportSize.width;
	// 	setScale(scale >= 1 ? 1 : scale);

	// 	setCurSlideId(id);
	// 	setSlideIdx(0);
	// 	setCurSlide(slideList[0]);
	// };
	// ----------

	useEffect(() => {
		const { pathname } = location;
		let editorType = 'slide';
		if (pathname.indexOf('/editor/slides') > -1) {
			editorType = 'slide';
			if (id) {
				getSlideInfo();
			}
		}
		if (pathname.indexOf('/editor/template') > -1) {
			editorType = 'template';
			if (id) {
				getTempInfo();
			}
		}
		console.log('editorType', editorType);
		setEditorType(editorType);
	}, [location, id]);

	useEffect(() => {
		// renderTest();
		// localDate();
		getStyleList();
		getTypeList();
		getColorList();
	}, []);

	return (
		<Spin loading={loading} tip="文件上传中...">
			<div className="ppt-editor">
				<div className="editor-form flex">
					<Button
						icon={<IconLeft />}
						onClick={() => {
							navigate(-1);
						}}
					/>
					<div className="flex-1" style={{ marginLeft: '15px' }}>
						<Form form={form} autoComplete="off" layout="inline" onSubmit={handleSave}>
							<FormItem field="name" rules={[{ required: true, message: '请输入名称' }]}>
								<Input style={{ width: '160px' }} placeholder="请输入模板名称" />
							</FormItem>
							<FormItem field="style" rules={[{ required: true, message: '请选择风格' }]}>
								<Select style={{ width: '160px' }} options={styleList} placeholder="请选择模板风格" allowClear />
							</FormItem>
							{editorType == 'template' && (
								<>
									<FormItem field="type" rules={[{ required: true, message: '请选择行业类型' }]}>
										<Select style={{ width: '160px' }} options={typeList} placeholder="请选择行业类型" allowClear />
									</FormItem>
									<FormItem field="color" rules={[{ required: true, message: '请选择色系' }]}>
										<Select style={{ width: '160px' }} options={colosList} placeholder="请选择模板色系" allowClear />
									</FormItem>
								</>
							)}
							<FormItem field="tags" rules={[{ required: true }]}>
								<InputTag allowClear placeholder="请输入标签后回车" style={{ width: 250 }} />
							</FormItem>
							{editorType == 'template' && (
								<FormItem field="price" rules={[{ required: true }]}>
									<InputNumber prefix="¥" style={{ width: 150 }} placeholder="请输入定价" />
								</FormItem>
							)}
							<FormItem>
								<Space>
									<Button type="primary" htmlType="submit" disabled={slideList.length == 0 || saveLoad}>
										保存
									</Button>
									{editorType == 'slide' && !id && (
										<Button className="upload-btn">
											<input className="upload-file" type="file" onChange={(e: any) => handleUpPpt(e)} name="" id="" />
											<span>上传PPT</span>
										</Button>
									)}
								</Space>
							</FormItem>
						</Form>
					</div>
				</div>
				<div className="editor-warp flex">
					<div className="editor-thumb">
						{slideList.map((item, index) => (
							<div className="thumb-item" key={index}>
								<span className="item-index">{index + 1}</span>
								<span className="item-del  flex justify-center items-center" onClick={() => onDelSlideItem(index)}>
									<IconDelete />
								</span>
								<div
									className={index == slideIdx ? 'item-content active' : 'item-content'}
									onClick={() => {
										setCurSlide(null);
										setSlideIdx(index);
										setCurSlideId(item.id);
										const times = setTimeout(() => {
											setCurSlide(slideList[index]);
											clearTimeout(times);
										}, 200);
									}}>
									<SlideComponent
										key={`thumb-${item.id}-${index}`}
										ref={(ref) => {
											if (ref) {
												slideRefMap.current[item.id] = ref;
											}
										}}
										data={item}
										layoutList={layoutList}
										viewportSize={viewportSize}
										// masterList={masterList}
										// themeMap={themeMap}
										scale={0.1328125}
										editable={false}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="editor-list flex-1 flex justify-center items-center">
						{curSlide && (
							<SlideComponent
								ref={(ref) => {
									if (ref) {
										curSlideRelMap.current = ref;
									}
								}}
								data={curSlide}
								layoutList={layoutList}
								viewportSize={viewportSize}
								// masterList={masterList}
								// themeMap={themeMap}
								scale={scale}
								editable={editorType == 'slide' ? true : false}
							/>
						)}
					</div>
					<div className="tool-panel">
						{editorType == 'slide' && (
							<StructureBind
								slideId={curSlideId}
								slideElement={curSlide?.elements || []}
								onRelBindCb={onRelBindCb}
								onRelImgCb={onRelImgCb}
							/>
						)}
						{editorType == 'template' && <SelectSlide styleList={styleList} onSelectCb={onSelectCb} />}
					</div>
				</div>
				{/* 弹窗 */}
				<Modal
					style={{ width: '240px' }}
					title={null}
					footer={null}
					visible={showSchedule}
					focusLock={true}
					closable={false}
					escToExit={false}
					maskClosable={false}>
					<div>
						<Progress width={200} type="circle" size="large" strokeWidth={6} percent={percent} />
					</div>
					{percent < 100 ? (
						<p style={{ fontSize: '16px', fontWeight: 500, textAlign: 'center' }}>正在上传中，请稍候...</p>
					) : (
						<>
							<div style={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', paddingTop: '10px' }}>
								上传完成！
							</div>
							<div style={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', paddingTop: '10px' }}>
								是否立即生成模板？
							</div>
							<div style={{ textAlign: 'center', paddingTop: '10px' }}>
								<Space>
									<Button
										type="primary"
										onClick={() => {
											setShowSchedule(false);
											setShowCreateTemp(true);
										}}>
										确定
									</Button>
									<Button
										onClick={() => {
											navigate('/slides/list');
										}}>
										取消
									</Button>
								</Space>
							</div>
						</>
					)}
				</Modal>

				<AddTempModal visible={showCreateTemp} {...createTempInfo} />
			</div>
		</Spin>
	);
};
