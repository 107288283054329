import { useContext } from 'react';
import { Layout, Dropdown, Avatar, Menu } from '@arco-design/web-react';
import { IconDown, IconPoweroff, IconUser, IconMenuFold, IconMenuUnfold } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';
import { delLogout } from '@/api/login';

import { delCookies, delSessionStorage } from '@/utils';

const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;
const RefTokenName = import.meta.env.VITE_APP_REFRESH_TOKEN_NAME;

export default (props: any) => {
	const { collapsed, onCollapse } = props;
	const Header = Layout.Header;
	const { userInfo } = useContext(GlobalContext);

	const onMenuItemClick = (key: string) => {
		if (key == 'Logout') {
			delLogout().then((res) => {
				if (res.code == 200) {
					delCookies(tokenName);
					delSessionStorage(RefTokenName);
					window.location.href = '/login';
				}
			});
		}
	};

	const dropList = (
		<Menu onClickMenuItem={onMenuItemClick}>
			<Menu.Item key="Logout">
				<IconPoweroff />
				退出登录
			</Menu.Item>
		</Menu>
	);

	return (
		<Header className="layout-header" style={{ paddingLeft: 20 }}>
			<div className="header-left" onClick={onCollapse}>
				{collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
			</div>
			<div className="user">
				<Dropdown droplist={dropList} position="bl">
					<span>
						<Avatar size={32} style={{ marginRight: '8px', cursor: 'pointer' }}>
							{userInfo?.avatar ? <img alt="avatar" src={userInfo?.avatar} /> : <IconUser />}
						</Avatar>
						{userInfo?.nickname}
						<IconDown />
					</span>
				</Dropdown>
			</div>
		</Header>
	);
};
