import { useEffect, useState } from 'react';
import { Select, Input, Pagination } from '@arco-design/web-react';
import { getFormatStructs, getFormatList } from '@/api/slides';
import { getSessionStorage } from '@/utils';

import './index.less';

interface IProps {
	styleList: any[];
	onSelectCb: (item: any) => void;
}

export default (props: IProps) => {
	const InputSearch = Input.Search;
	const { styleList, onSelectCb } = props;

	const cdnUrl = getSessionStorage('cdnUrl');

	const [layoutList, setLayoutList] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [params, setParsams] = useState({
		style: undefined,
		layout: undefined,
		keywords: ''
	});
	const [list, setList] = useState<any[]>([]);

	const getLayoutList = async () => {
		let res = await getFormatStructs('1');
		setLayoutList(res.data);
	};

	const getList = async () => {
		let res = await getFormatList({
			...params,
			page,
			size: 10
		});

		setList(res.data.list);
		setTotal(res.data.total);
	};

	useEffect(() => {
		getList();
	}, [page]);

	useEffect(() => {
		getLayoutList();
	}, []);

	return (
		<>
			<div className="panel-head">选择版式</div>
			<div className="panel-content">
				<div className="slide-filter">
					<div className="flex justify-between" style={{ marginBottom: '10px' }}>
						<Select
							className="flex-1"
							style={{ marginRight: '10px' }}
							options={styleList}
							placeholder="请选择风格"
							onChange={(value) => {
								params.style = value;
								setParsams({ ...params });
							}}
						/>
						<Select
							className="flex-1"
							options={layoutList}
							placeholder="请选择结构"
							onChange={(value) => {
								params.layout = value;
								setParsams({ ...params });
							}}
						/>
					</div>
					<InputSearch
						placeholder="请输入关键字"
						searchButton={true}
						onChange={(value) => {
							params.keywords = value;
							setParsams({ ...params });
						}}
						onSearch={() => {
							if (page == 1) {
								getList();
							} else {
								setPage(1);
							}
						}}
					/>
				</div>
				<div className="slide-list">
					{list.map((item) => (
						<div key={`select-slide-${item.id}`} className="list-item" onClick={() => onSelectCb(item)}>
							<img src={cdnUrl + item.preview + '?imageView2/1/w/480/h/240'} />
							<p className="info">{item.name}</p>
						</div>
					))}
				</div>
				<div className="slide-page flex justify-center">
					<Pagination
						simple
						total={total}
						current={page}
						pageSize={10}
						size="small"
						onChange={(page) => {
							setPage(page);
						}}
					/>
				</div>
			</div>
		</>
	);
};
