import { Message } from '@arco-design/web-react';
import axios, { AxiosResponse } from 'axios';
import { getCookies, setCookies, setSessionStorage, getSessionStorage, delSessionStorage, delCookies } from '@/utils';

declare module 'axios' {
	interface AxiosResponse<T = any> {
		code: number;
		msg: string;
	}
}

const baseURL = import.meta.env.VITE_APP_FETCH_BASEURL;
const tokenName = import.meta.env.VITE_APP_TOKEN_NAME;
const RefTokenName = import.meta.env.VITE_APP_REFRESH_TOKEN_NAME;

// 保存是否正在刷新的状态
let isRefreshing = false;
// 保存挂起的请求（即在刷新 token 后需要重新发送的请求）
let pendingRequests: any[] = [];

const instance = axios.create({
	baseURL: baseURL,
	timeout: 60000
});

// 请求拦截器
instance.interceptors.request.use(
	(config: any) => {
		if (config.headers['Authorization']) {
		} else {
			let token = getCookies(tokenName);
			if (token) {
				config.headers = {
					...config.headers,
					Authorization: `Bearer ${token}`
				};
			}
		}
		return config;
	},
	(error) => {
		error.data = {};
		error.data.msg = '服务器异常，请联系管理员！';
		return Promise.resolve(error);
	}
);

// 响应拦截器
instance.interceptors.response.use(
	(response: AxiosResponse) => {
		const status = response.status;
		if (status < 200 || status >= 300) {
			response.data.msg = '服务器异常，请联系管理员！';
		}
		return response.data;
	},
	(error: any) => {
		const originalRequest = error.config;
		console.log('000000', error.response.status === 401 && !originalRequest._retry);

		if (error.response.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				// 如果正在刷新 token，将请求添加到 pendingRequests 中
				return new Promise((resolve) => {
					pendingRequests.push(() => {
						originalRequest.headers['Authorization'] = 'Bearer ' + getCookies(tokenName);
						resolve(instance(originalRequest));
					});
				});
			}
			originalRequest._retry = true;
			isRefreshing = true;

			return new Promise((resolve, reject) => {
				// 发送刷新 token 请求
				const refreshToken = getSessionStorage(RefTokenName);

				axios
					.post(
						`${baseURL}/uaa/oauth/token`,
						{
							grant_type: 'refresh_token',
							refresh_token: refreshToken
						},
						{
							headers: {
								Authorization: 'Basic YWRtaW46MTIzNDU2',
								'Content-Type': 'application/x-www-form-urlencoded'
							}
						}
					)
					.then((result: any) => {
						if (result.data.code == 200) {
							const { data } = result.data;
							// 设置新的 token
							setCookies(tokenName, data.access_token, data.expires_in);
							// 设置刷新 token，存入 storage
							setSessionStorage(RefTokenName, data.refresh_token);

							originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
							// 执行挂起的请求
							pendingRequests.forEach((callback) => callback());
							pendingRequests = [];
							resolve(instance(originalRequest));
						} else {
							console.log('432143214321-1');
							delCookies(tokenName);
							delSessionStorage(RefTokenName);
							Message.error('登录过期，请重新登录');
							window.location.href = '/login';
							reject();
						}
					})
					.catch((err: any) => {
						console.log('432143214321', err);
						delCookies(tokenName);
						delSessionStorage(RefTokenName);
						Message.error(err.message);
						// window.location.href = '/login';
						// reject();
					})
					.finally(() => {
						console.log('432143214321-3');
						isRefreshing = false;
					});
			});
		} else {
			console.log('fdsafdsafdsa');
			let msg = '请求超时或服务器异常，请检查网络或联系管理员！';
			if (error?.response?.data?.msg) {
				msg = error?.response?.data?.msg;
			}
			error.message = msg;
			return Promise.reject(error.response);
		}
	}
);

export default instance;
