import { TextProps, Textbox } from 'fabric';

import { Vertical, TextLineData, TextData } from '@/types/element';
import { getColorOrGradient } from './base';

export function getTextTop(vertical: Vertical, height: number, textHeight: number) {
	if (vertical == 'center') {
		return (height - textHeight) / 2;
	}

	if (vertical == 'bottom') {
		return height - textHeight;
	}
	return 0;
}

export function getTextStyle(
	width: number,
	height: number,
	text: TextLineData['text'],
	adjustSize?: number,
	fontSize?: number
) {
	let maxFontSize = 0;
	const styles = {
		0: {
			...text.reduce((total, cur) => {
				const tLen = Object.keys(total).length;
				const obj = (cur.text || '').split('').reduce((t, _c, i) => {
					const fSize = Math.floor((fontSize || cur.fontSize || 18) / 0.75) + (adjustSize || 0);
					maxFontSize = Math.max(maxFontSize, fSize);
					const style: Partial<TextProps> = {
						fontSize: fSize,
						fill: 'transparent',
						fontWeight: cur.fontWeight,
						fontStyle: cur.fontStyle,
						underline: cur.underline,
						linethrough: cur.lineThrough
					};
					// 兼容旧数据，fontFamily已废弃
					if (cur.fontFamily) style.fontFamily = cur.fontFamily;
					if (cur.lang == 'zh-CN') {
						if (cur.eaTypeface) style.fontFamily = cur.eaTypeface;
					} else {
						if (cur.latinTypeface) style.fontFamily = cur.latinTypeface;
					}
					if (cur.color)
						style.fill = getColorOrGradient(cur.color, {
							width: width,
							height: height
						});
					if (cur.border?.color) {
						style.stroke = getColorOrGradient(cur.border.color, {
							width: width,
							height: height
						});
						style.strokeWidth = 1;
					}
					return {
						...t,
						[tLen + i]: style
					};
				}, {});

				return {
					...total,
					...obj
				};
			}, {})
		}
	};

	return { styles, maxFontSize };
}

export function updateTextFontSize(texts: Textbox[], data: TextData, adjustNum: number, fontSize?: number) {
	let sumHeight = 0;
	let maxFontSize = 0;
	texts.forEach((text, index) => {
		const line = data.text[index];
		const { styles, maxFontSize: _maxFontSize } = getTextStyle(data.width, data.height, line.text, adjustNum, fontSize);
		maxFontSize = Math.max(_maxFontSize, maxFontSize);
		text.set({
			styles,
			top: sumHeight
		});
		sumHeight += text.height * line.lineHeight;
	});

	let lineSum = 0;
	texts.forEach((text, index) => {
		const line = data.text[index];
		text.set({
			top: lineSum + (text.height * (line.lineHeight - 1)) / 2 - sumHeight / 2
		});
		lineSum += text.height * line.lineHeight;
	});

	return {
		sumHeight,
		maxFontSize
	};
}

interface ConditionProps {
	mainHeight?: number;
	sumHeight?: number;
	mainWidth?: number;
	realWidth?: number;
}

const adjustHandleList = [
	{
		getCondition: ({ mainHeight = 0, sumHeight = 0, mainWidth = 0, realWidth = 0 }: ConditionProps) =>
			mainHeight - sumHeight > 0 && mainWidth - realWidth > 0,
		defaultValue: 1,
		getAdjustNum: (adjustNum: number) => adjustNum + 1
	},
	{
		getCondition: ({ mainHeight = 0, sumHeight = 0, mainWidth = 0, realWidth = 0 }: ConditionProps) =>
			sumHeight - mainHeight > 0 || realWidth - mainWidth > 0,
		defaultValue: -1,
		getAdjustNum: (adjustNum: number) => adjustNum - 1
	}
];

export function adjustTextFontSize(
	texts: Textbox[],
	data: TextData,
	{ sumHeight = 0, mainHeight, mainWidth = 0 }: ConditionProps
) {
	let adjustNum: number = -999;
	let maxFontSize = 0;
	let realWidth = texts.reduce((total, cur) => Math.max(total, cur.getMinWidth()), 0);

	for (const handle of adjustHandleList) {
		if (handle.getCondition({ mainHeight, sumHeight, mainWidth, realWidth })) {
			if (adjustNum == -999) {
				adjustNum = handle.defaultValue;
			} else {
				adjustNum = adjustNum - 2;
			}
			while (handle.getCondition({ mainHeight, sumHeight, mainWidth, realWidth })) {
				const updateRes = updateTextFontSize(texts, data, adjustNum);
				sumHeight = updateRes.sumHeight;
				maxFontSize = updateRes.maxFontSize;
				adjustNum = handle.getAdjustNum(adjustNum);
				realWidth = texts.reduce((total, cur) => Math.max(total, cur.getMinWidth()), 0);
				// console.log(data.name, '调节文本大小：', adjustNum, '文本高度：', sumHeight, '框高度：', data.height, '文本总高度：', mainHeight)
			}
			// return { sumHeight }
		}
	}
	return { sumHeight, maxFontSize };
}
