import fetch from '@/common/fetch';

//
export const getHotWorkList = (param: any) =>
	fetch({
		url: '/res/v1/ai/res/hot_word/list',
		method: 'get',
		params: param
	});

export const postHotWork = (data: any) =>
	fetch({
		url: '/res/v1/ai/res/hot_word',
		method: 'post',
		data: data
	});

export const putHotWork = (id: string, data: any) =>
	fetch({
		url: `/res/v1/ai/res/hot_word/${id}`,
		method: 'put',
		data: data
	});
export const delHotWork = (id: string | number) =>
	fetch({
		url: `/res/v1/ai/res/hot_word/${id}`,
		method: 'DELETE'
	});
