import { Path, RectProps } from 'fabric'

import { Element } from '@/types/element'
import { getBaseCommonProps } from '../base'

export default async function getMathMultiply(data: Element) {
  const option: Partial<RectProps> = await getBaseCommonProps(data)
  const { width, height } = data
  const paddingRadio = 0.25
  const radius = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.24
  const size = Math.min(width, height) * Math.min(radius, 1 - paddingRadio * 2) || 1
  const radian = Math.atan2(height, width)
  const hypotenuse = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2))
  const realHypotenuse = hypotenuse * paddingRadio
  const sizeX = Math.sin(radian) * size / 2
  const sizeY = Math.cos(radian) * size / 2
  const paddingX = Math.cos(radian) * realHypotenuse
  const paddingY = Math.sin(radian) * realHypotenuse
  const xDiffer = (height / 2 - paddingY - sizeY) / Math.tan(radian)
  const yDiffer = Math.tan(radian) * (width / 2 - paddingX - sizeX)

  const path = `
    M ${paddingX - sizeX} ${paddingY + sizeY}
    L ${paddingX + sizeX} ${paddingY - sizeY}
    L ${width / 2} ${paddingY - sizeY + yDiffer}
    L ${width - paddingX - sizeX} ${paddingY - sizeY}
    L ${width - paddingX + sizeX} ${paddingY + sizeY}
    L ${width - paddingX + sizeX - xDiffer} ${height / 2}
    L ${width - paddingX + sizeX} ${height - paddingY - sizeY}
    L ${width - paddingX - sizeX} ${height - paddingY + sizeY}
    L ${width / 2} ${height - paddingY + sizeY - yDiffer}
    L ${paddingX + sizeX} ${height - paddingY + sizeY}
    L ${paddingX - sizeX} ${height - paddingY - sizeY}
    L ${paddingX - sizeX + xDiffer} ${height / 2}
    Z
  `

  return new Path(path, option)
}