import { useEffect, useState } from 'react';
import { Table, Space, Button, Popconfirm, Message } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';

import { getHotWorkList, delHotWork } from '@/api/hotWork';

import { formatDate } from '@/utils';

import WorkInfoModal from './info';

export default () => {
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 100
		},
		{
			title: 'AI热词',
			dataIndex: 'words'
		},
		{
			title: '创建人',
			dataIndex: 'creatorName'
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '修改人',
			dataIndex: 'modifierName'
		},
		{
			title: '修改时间',
			dataIndex: 'updateTime',
			width: 180,
			render: (_: any, record: any) => <>{record?.updateTime ? formatDate(record.updateTime) : ''}</>
		},
		{
			title: '操作',
			width: 180,
			key: 'action',
			render: (_: any, record: any) => (
				<Space>
					<Button type="text" onClick={() => handleShowEdit(record)}>
						编辑
					</Button>
					<Popconfirm position="left" title="是否要删除此项？" onOk={() => handleDel(record.id)}>
						<Button type="text">删除</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const [showEditModal, setShowEditModal] = useState(false);
	const [editInfo, setEditorInfo] = useState(null);

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const getList = async () => {
		setLoading(true);
		let res = await getHotWorkList({
			page,
			size: 20
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	const handleShowEdit = (item: any) => {
		setEditorInfo(item);
		setShowEditModal(true);
	};
	// const handleStatus = async (id: number | string) => {};
	const handleDel = async (id: number | string) => {
		let res = await delHotWork(id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};

	useEffect(() => {
		getList();
	}, [page]);

	return (
		<>
			<div className="page-filter">
				<Button type="primary" icon={<IconPlus />} onClick={() => handleShowEdit(null)}>
					添加热词
				</Button>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					pagination={{
						showTotal: true,
						total: total,
						pageSize: 20,
						current: page,
						onChange: onPageChange
					}}
				/>
			</div>

			<WorkInfoModal info={editInfo} visible={showEditModal} onOk={getList} onCancel={() => setShowEditModal(false)} />
		</>
	);
};
