// 添加字体
export const addFontFamily = (fontFamily: string, url: string) => {
	return new Promise((resolve, reject) => {
		const link = url.replace('.ttf', '.woff');
		let font = new FontFace(fontFamily, `url(${link})`);
		font
			.load()
			.then(function () {
				document.fonts.add(font);
				resolve(true);
			})
			.catch(() => {
				reject();
			});
	});
};
